import React from "react";

const MultiTiles = (props) => {
  const {
    dollarSign,
    color1,
    color2,
    tile1value,
    tile1text,
    tile2value,
    tile2text,
  } = props;

  return (
    <>
      <div class="row rounded mb-3 mx-0" style={{backgroundColor: '#f5f5f5'}}>
        <div class="col-sm-6 ps-1 pe-0">
          <div class="card bg-transparent" style={{ border: '0' }}>
            <div class="card-body text-center px-0 py-3 pt-4">
              <h3 class="card-title mb-1" style={{ color: `${color1}` }}>
                {dollarSign}
                {tile1value}
              </h3>
              <p class="card-text mb-0" style={{ fontSize: "0.75rem" }}>{tile1text}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 ps-0 pe-1">
          <div class="card bg-transparent" style={{ border: '0' }}>
            <div class="card-body text-center px-0 py-3 pt-4">
              <h3 class="card-title mb-1" style={{ color: `${color2}` }}>
                {dollarSign}
                {tile2value}
              </h3>
              <p class="card-text mb-0" style={{ fontSize: "0.75rem" }}>{tile2text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiTiles;
