import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import $ from "jquery";
import SingleTile from "pages/dashboardcomponents/TilesInside/SingleTile";
import BacklogBarChart from "pages/dashboardcomponents/BacklogBarChart";
import BacklogDonut from "pages/dashboardcomponents/BacklogDonut";
import BacklogTable from "pages/dashboardcomponents/BacklogTable";
// import { CloudUploadIcon, CollectionIcon, FireIcon, PlusIcon, ShieldExclamationIcon, UserAddIcon } from "@heroicons/react/solid";
// import { Col, Row, Button, Dropdown, ButtonGroup } from 'react-bootstrap';

// import { RankingTable } from "components/Tables";
// import { BarChartWidget, RatingsWidget, CardWidget, ListChartWidget } from "components/Widgets";
// import { appRanking } from "data/charts";

var filterObject = {
  BusinessUnit: 0,
  Portfolio: 0,
  IdeaStatusKey: 0,
  ApprovalKey: 0,
};

var isChangeCalled = false;

export default () => {
  const [wb, setWb] = useState({});
  const [dropDownFilters, setDropDownFilters] = useState({ filterObject });

  var workbook;
  useEffect(() => {
    if (
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    ) {
      importExcel();
      function importExcel() {
        makeRequest(
          "GET",
          "./olap_dummy_data.xlsx",
          // success
          function (data) {
            workbook = convertDataToWorkbook(data);
            setWb(workbook);
          },
          // error
          function (error) {
            throw error;
          }
        );
      }
      function convertDataToWorkbook(data) {
        // convert data to binary string /
        var data = new Uint8Array(data);
        var arr = new Array();

        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }

        var bstr = arr.join("");

        return XLSX.read(bstr, { type: "binary" });
      }
      // XMLHttpRequest in promise format
      function makeRequest(method, url, success, error) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.open("GET", url, true);
        httpRequest.responseType = "arraybuffer";

        httpRequest.open(method, url);
        httpRequest.onload = function () {
          success(httpRequest.response);
        };
        httpRequest.onerror = function () {
          error(httpRequest.response);
        };
        httpRequest.send();
      }
    }
    return;
  }, [wb]);

  var dropdownsData = getSolutionBacklogDropdownsData(wb, filterObject);

  function getSolutionBacklogDropdownsData(workbookData, filterObject) {
    var objFilteredDropdwonsData = {};
    if (Object.keys(workbookData).length > 0) {

      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      if (filterObject.BusinessUnit != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey === filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey === filterObject.Portfolio;
        })
      }
      if (filterObject.IdeaStatusKey != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.IdeaStatusKey === filterObject.IdeaStatusKey;
        })
      }
      if (filterObject.ApprovalKey != 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.ApprovalKey === filterObject.ApprovalKey;
        })
      }

      var bussinessUnitKeys = filteredSheetData.map(ele => {
        return ele.BusinessUnitKey
      })

      var uniqueBusinessUnitKeys = bussinessUnitKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      //DimBU Sheet data
      var dimBUSheet = workbookData.Sheets['DimBU'];
      var dimBUData = XLSX.utils.sheet_to_json(dimBUSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filterBusinessUnitKeys = [];
      uniqueBusinessUnitKeys.forEach(el => {
        dimBUData.forEach(element => {
          if (element.BusinessUnitKey === el) {
            filterBusinessUnitKeys.push(element);
          };
        });
      });

      objFilteredDropdwonsData["Business Unit"] = filterBusinessUnitKeys;

      var portfolioKeys = filteredSheetData.map(ele => {
        return ele.PortfolioKey
      })

      var uniquePortfolioKeys = portfolioKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      //Portfolio Sheet data
      var dimPortfolioSheet = workbookData.Sheets['DimPortfolio'];
      var dimPortfolioData = XLSX.utils.sheet_to_json(dimPortfolioSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filterPortfolios = [];
      uniquePortfolioKeys.forEach(el => {
        dimPortfolioData.forEach(element => {
          if (element.PortfolioKey === el) {
            filterPortfolios.push(element);
          };
        });
      });

      objFilteredDropdwonsData["Team"] = filterPortfolios;

      //DimIdeaStatus Sheet data
      var DimIdeaStatusSheet = workbookData.Sheets['DimIdeaStatus'];
      var DimIdeaStatusData = XLSX.utils.sheet_to_json(DimIdeaStatusSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      objFilteredDropdwonsData["Idea Status"] = DimIdeaStatusData;


      var approvalKeys = filteredSheetData.map(ele => {
        return ele.ApprovalKey
      })

      var uniqueApprovalKeys = approvalKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      //DimApproval Sheet data
      var dimApprovalSheet = workbookData.Sheets['DimApproval'];
      var dimApprovalData = XLSX.utils.sheet_to_json(dimApprovalSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filterApprovals = [];
      uniqueApprovalKeys.forEach(el => {
        dimApprovalData.forEach(element => {
          if (element.ApprovalKey === el) {
            filterApprovals.push(element);
          };
        });
      });

      objFilteredDropdwonsData["Approved for Development"] = filterApprovals;
    }
    return objFilteredDropdwonsData;
  }

  const loadDropdownsDefaultData = (filterDropdownsData) => {
    if (
      Object.keys(filterDropdownsData).length > 0 &&
      isChangeCalled === false
    ) {
      // Business Unit Dropdown
      var buOption = "<option value='0'>All</option>";
      filterDropdownsData["Business Unit"].forEach((element) => {
        buOption += `<option value= ${element.BusinessUnitKey} > ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(buOption);

      // Portfolio Dropdown
      var PortfolioOption = "<option value='0'>All</option>";
      filterDropdownsData["Team"].forEach((element) => {
        PortfolioOption += `<option value= ${element.PortfolioKey} > ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOption);

      // Idea Status Dropdown
      var ideaStatusOptions = "<option value='0'>All</option>";

      filterDropdownsData["Idea Status"].forEach((element) => {
        ideaStatusOptions += `<option value= "${element.IdeaStatusKey}" > ${element.IdeaStatus} </option>`;
      });
      $("#ideaStatusDropdown").empty().append(ideaStatusOptions);

      // Approved for Development Dropdown
      var aprrovedForDevOption = "<option value='0'>All</option>";
      filterDropdownsData["Approved for Development"].forEach((element) => {
        aprrovedForDevOption += `<option value= "${element.ApprovalKey}" > ${element["Approved for Development"]} </option>`;
      });
      $("#aprrovedForDevKey").empty().append(aprrovedForDevOption);
    }
  };

  loadDropdownsDefaultData(dropdownsData);

  // Business Unit Onchange method
  const handleBUDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt(e.target.value);
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.IdeaStatusKey = parseInt($("#ideaStatusDropdown").val());
    filterObject.ApprovalKey = parseInt($("#aprrovedForDevKey").val());

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = getSolutionBacklogDropdownsData(
        wb,
        filterObject
      );

      // Portfolio Dropdown
      var PortfolioOption = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOption += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOption);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Idea Status Dropdown
      var ideaStatusOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Idea Status"].forEach((element) => {
        ideaStatusOptions += `<option value= "${element.IdeaStatusKey}" > ${element.IdeaStatus} </option>`;
      });
      $("#ideaStatusDropdown").empty().append(ideaStatusOptions);
      $("#ideaStatusDropdown").val(filterObject.IdeaStatusKey);

      // Approved for Development Dropdown
      var aprrovedForDevOption = "<option value='0'>All</option>";

      filteredDropdownsData["Approved for Development"].forEach((element) => {
        aprrovedForDevOption += `<option value= "${element.ApprovalKey}" > ${element["Approved for Development"]} </option>`;
      });
      $("#aprrovedForDevKey").empty().append(aprrovedForDevOption);
      $("#aprrovedForDevKey").val(filterObject.ApprovalKey);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };

  //PortFolio Onchange method
  const handlePFDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt(e.target.value);
    filterObject.IdeaStatusKey = parseInt($("#ideaStatusDropdown").val());
    filterObject.ApprovalKey = parseInt($("#aprrovedForDevKey").val());

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = getSolutionBacklogDropdownsData(
        wb,
        filterObject
      );

      // Business Unit Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);


      // Idea Status Dropdown
      var ideaStatusOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Idea Status"].forEach((element) => {
        ideaStatusOptions += `<option value= "${element.IdeaStatusKey}" > ${element.IdeaStatus} </option>`;
      });
      $("#ideaStatusDropdown").empty().append(ideaStatusOptions);
      $("#ideaStatusDropdown").val(filterObject.IdeaStatusKey);

      // Approved for Development Dropdown
      var aprrovedForDevOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Approved for Development"].forEach((element) => {
        aprrovedForDevOptions += `<option value= "${element.ApprovalKey}" > ${element["Approved for Development"]} </option>`;
      });
      $("#aprrovedForDevKey").empty().append(aprrovedForDevOptions);
      $("#aprrovedForDevKey").val(filterObject.ApprovalKey);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };

  // Idea Status Onchange method
  const handleIdeaStatusDropdownChange = (e) => {
    //-------------
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.IdeaStatusKey = parseInt(e.target.value);
    filterObject.ApprovalKey = parseInt($("#aprrovedForDevKey").val());

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = getSolutionBacklogDropdownsData(
        wb,
        filterObject
      );

      // Business Unit Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);

      // Portfolio Dropdown
      var PortfolioOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOptions += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOptions);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Approved for Development Dropdown
      var aprrovedForDevOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Approved for Development"].forEach((element) => {
        aprrovedForDevOptions += `<option value="${element.ApprovalKey}" > ${element["Approved for Development"]} </option>`;
      });
      $("#aprrovedForDevKey").empty().append(aprrovedForDevOptions);
      $("#aprrovedForDevKey").val(filterObject.ApprovalKey);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };

  // Approved for Development Onchange method
  const handleAPDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.StageOfDevelopment = parseInt($("#ideaStatusDropdown").val());
    filterObject.ApprovalKey = parseInt(e.target.value);

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = getSolutionBacklogDropdownsData(
        wb,
        filterObject
      );

      // Business Unit Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);

      // Portfolio Dropdown
      var PortfolioOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOptions += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOptions);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Idea Status Dropdown
      var ideaStatusOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Idea Status"].forEach((element) => {
        ideaStatusOptions += `<option value= "${element.IdeaStatusKey}" > ${element.IdeaStatus} </option>`;
      });
      $("#ideaStatusDropdown").empty().append(ideaStatusOptions);
      $("#ideaStatusDropdown").val(filterObject.IdeaStatusKey);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };

  var portfolioDonutData = getBacklogDonutsData(wb, filterObject, "Portfolio");
  var KeyDriverDonutData = getBacklogDonutsData(wb, filterObject, "KeyDriver");
  var ProcessComplexityDonutData = getBacklogDonutsData(wb, filterObject, "ProcessComplexity");

  function getBacklogDonutsData(workbookData, filterObject, chartName) {
    var data = {
      values: [],
      labels: [],
    }

    var labelSheet;
    var labelSheetData;

    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
        })
      }
      if (filterObject.IdeaStatusKey !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.IdeaStatusKey !== "" && ele.IdeaStatusKey === filterObject.IdeaStatusKey;
        })
      }
      if (filterObject.ApprovalKey !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.ApprovalKey !== "" && ele.ApprovalKey === filterObject.ApprovalKey;
        })
      }

      if (chartName === "Portfolio") {

        labelSheet = workbookData.Sheets['DimPortfolio'];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true, defval: "",
          blankRows: false,
        });


        var filteredRecords = [];
        labelSheetData.forEach(lbl => {
          filteredSheetData.forEach(value => {
            if (value.PortfolioKey === lbl.PortfolioKey) {
              var obj = {
                "PortfolioKey": lbl.PortfolioKey,
                "Portfolio": lbl.Portfolio,
              }
              filteredRecords.push(obj);
            }
          })
        })
        var result = filteredRecords.reduce((acc, o) => (acc[o.Portfolio] = (acc[o.Portfolio] || 0) + 1, acc), {});
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      }
      else if (chartName === "KeyDriver") {

        labelSheet = workbookData.Sheets['DimDriver'];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true, defval: "",
          blankRows: false,
        });

        var filteredRecords = [];
        labelSheetData.forEach(lbl => {
          filteredSheetData.forEach(value => {
            if (value.DriverKey === lbl.DriverKey) {
              var obj = {
                "DriverKey": lbl.DriverKey,
                "Driver": lbl.Driver,
              }
              filteredRecords.push(obj);
            }
          })
        })

        var result = filteredRecords.reduce((acc, o) => (acc[o.Driver] = (acc[o.Driver] || 0) + 1, acc), {});
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      }
      else if (chartName === "ProcessComplexity") {

        labelSheet = workbookData.Sheets["DimComplexity"];
        labelSheetData = XLSX.utils.sheet_to_json(labelSheet, {
          raw: true, defval: "",
          blankRows: false,
        });

        var filteredRecords = [];
        labelSheetData.forEach(lbl => {
          filteredSheetData.forEach(value => {
            if (value.ComplexityKey === lbl.ComplexityKey) {
              var obj = {
                "ComplexityKey": lbl.ComplexityKey,
                "Complexity": lbl.Complexity,
              }
              filteredRecords.push(obj);
            }
          })
        })

        var result = filteredRecords.reduce((acc, o) => (acc[o.Complexity] = (acc[o.Complexity] || 0) + 1, acc), {});
        data.labels = Object.keys(result);
        data.values = Object.values(result);
      }
    }
    return data;
  }

  function automationBacklogTiles(workbookData, filterObject){
    var data = {
        "EstThreeYearReturn": 0,
        "NetThreeYearReturn": 0,
        "EstDevelopmentCost": 0,
        "RequiredFTE": 0,
        "CurrentResourceFTE": 0,
    };

    if (Object.keys(workbookData).length > 0) {

        var factSolutionSheet = workbookData.Sheets['FactSolution'];
        var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
            raw: true, defval: "",
            blankRows: false,
        });

        var filteredSheetData = sheetData;

        //filter fact solution sheet based on selected filters
        if (filterObject.BusinessUnit !== 0) {
            filteredSheetData = filteredSheetData.filter(ele => {
                return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
            })
        }
        if (filterObject.Portfolio !== 0) {
            filteredSheetData = filteredSheetData.filter(ele => {
                return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
            })
        }
        if (filterObject.IdeaStatusKey !== 0) {
            filteredSheetData = filteredSheetData.filter(ele => {
                return ele.IdeaStatusKey !== "" && ele.IdeaStatusKey === filterObject.IdeaStatusKey;
            })
        }
        if (filterObject.ApprovalKey !== 0) {
            filteredSheetData = filteredSheetData.filter(ele => {
                return ele.ApprovalKey !== "" && ele.ApprovalKey === filterObject.ApprovalKey;
            })
        }

        //DimApproval Sheet data
        var dimApprovalSheet = workbookData.Sheets['DimApproval'];
        var dimApprovalData = XLSX.utils.sheet_to_json(dimApprovalSheet, {
            raw: true, defval: "",
            blankRows: false,
        });

        //filter fact solution sheet records based upon their Approval Status
        //Calculate NetThreeYearReturn
        var mappedData = [];
        var lookup = []
        debugger;
        dimApprovalData.forEach(dimApproval => {
            filteredSheetData.forEach(factSolution => {
                if (factSolution.ApprovalKey === dimApproval.ApprovalKey) {

                    if(lookup.indexOf(factSolution.ApprovalKey) > -1){
                        var index = lookup.indexOf(factSolution.ApprovalKey);
    
                        mappedData[index].EstThreeYearReturn += (factSolution.EstThreeYearReturn === "" ? 0 : factSolution.EstThreeYearReturn);
                        mappedData[index].EstAnnualOpsCost += (factSolution.EstThreeYearReturn === "" ? 0 : factSolution.EstThreeYearReturn);
                        mappedData[index].EstDevelopmentCost += (factSolution.EstDevelopmentCost === "" ? 0 : factSolution.EstDevelopmentCost);
                        mappedData[index].EstDevelopmentEffortWks += (factSolution.EstDevelopmentEffortWks === "" ? 0 : factSolution.EstDevelopmentEffortWks);
                        
                        var sumOfAnnualOpsCostandDevCost = (factSolution.EstAnnualOpsCost*3) + factSolution.EstDevelopmentCost;

                        if(sumOfAnnualOpsCostandDevCost > factSolution.EstThreeYearReturn){
                            factSolution.NetThreeYearReturn = sumOfAnnualOpsCostandDevCost - factSolution.EstThreeYearReturn;
                        }
                        else{
                            factSolution.NetThreeYearReturn = factSolution.EstThreeYearReturn - sumOfAnnualOpsCostandDevCost;
                        }
                        mappedData[index].NetThreeYearReturn += factSolution.NetThreeYearReturn;
                    }
                    else{

                        factSolution.EstThreeYearReturn = factSolution.EstThreeYearReturn === "" ? 0 : factSolution.EstThreeYearReturn;
                        factSolution.EstAnnualOpsCost = factSolution.EstAnnualOpsCost === "" ? 0 : factSolution.EstAnnualOpsCost;
                        factSolution.EstDevelopmentCost = factSolution.EstDevelopmentCost === "" ? 0 : factSolution.EstDevelopmentCost;
                        factSolution.EstDevelopmentEffortWks = factSolution.EstDevelopmentEffortWks == "" ? 0 : factSolution.EstDevelopmentEffortWks;

                        var sumOfAnnualOpsCostandDevCost = (factSolution.EstAnnualOpsCost*3) + factSolution.EstDevelopmentCost;

                        if(sumOfAnnualOpsCostandDevCost > factSolution.EstThreeYearReturn){
                            factSolution.NetThreeYearReturn = sumOfAnnualOpsCostandDevCost - factSolution.EstThreeYearReturn;
                        }
                        else{
                            factSolution.NetThreeYearReturn = factSolution.EstThreeYearReturn - sumOfAnnualOpsCostandDevCost;
                        }

                        factSolution["Approved for Development"] = dimApproval["Approved for Development"];
                        lookup.push(factSolution.ApprovalKey);
                        mappedData.push(factSolution);
                    }
                }
            })
        })

        mappedData.forEach(element => {
            data.EstDevelopmentCost += element.EstDevelopmentCost;
            data.EstThreeYearReturn += element.EstThreeYearReturn;
            data.NetThreeYearReturn += element.NetThreeYearReturn;
        })
    }
    return data;
}


  function kFormatterWithDecimal(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  function kFormatterWithoutDecimal(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <>
      <div className="p-3 mt-5 rounded" style={{ backgroundColor: "white" }}>
        <div
          className="row rounded mx-0 mb-3 py-3"
          style={{ background: "#e1e1e1" }}
        >
          <div className="col-md-3 ">
            <label>Portfolio</label>
            <select
              className="selectPF rounded"
              id="portFolioKey"
              onChange={handlePFDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3">
            <label>Business Unit</label>
            <select
              className="selectBU rounded"
              id="businessUnitKey"
              onChange={handleBUDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3 ">
            <label>Idea Status</label>
            <select
              className="selectSD rounded"
              id="ideaStatusDropdown"
              onChange={handleIdeaStatusDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3 ">
            <label>Approved for Development</label>
            <select
              className="selectSN rounded"
              id="aprrovedForDevKey"
              onChange={handleAPDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <BacklogBarChart wb={wb} BarChartFilterObject={dropDownFilters} />
          </div>
          <div className="col-md-3">
            <BacklogDonut
              actualDonutData={portfolioDonutData}
              chartTitle="Portfolio"
            />
          </div>
          <div className="col-md-3">
            <BacklogDonut
              actualDonutData={KeyDriverDonutData}
              chartTitle="Key Driver"
            />
          </div>
          <div className="col-md-3">
            <BacklogDonut
              actualDonutData={ProcessComplexityDonutData}
              chartTitle="Process Complexity"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div
              className="rounded my-1 py-1 mb-3"
              style={{
                background: "#e1e1e1",
                textAlign: "center",
                fontWeight: "bold",
                padding: "1px",
              }}
            >
              <h6 style={{ marginBottom: "0" }}>Overview<a class="info" style={{
                border: '1px solid #000',
                borderRadius: '50px',
                fontSize: '9px',
                margin: 0,
                fontWeight: 'bold',
                padding: '0px 4px',
                color: 'black',
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}>i</a></h6>
            </div>
            <SingleTile
              color2={"#0059ff"}
              dollarSign="$"
              tilevalue={kFormatterWithDecimal(automationBacklogTiles(wb, filterObject).EstThreeYearReturn)}
              tiletext="3Y Return"
            />
            <SingleTile
              color2={"#0059ff"}
              dollarSign="$"
              tilevalue={kFormatterWithDecimal(automationBacklogTiles(wb, filterObject).NetThreeYearReturn)}
              tiletext="3Y Net Return"
            />
            <SingleTile
              color2={"#0059ff"}
              dollarSign="$"
              tilevalue={kFormatterWithoutDecimal(automationBacklogTiles(wb, filterObject).EstDevelopmentCost)}
              tiletext="Development Cost"
            />
            <SingleTile
              color2={"#0059ff"}
              tilevalue={kFormatterWithDecimal(0)}
              tiletext="Required FTE (6 Months)"
            />
            <SingleTile
              color2={"#0059ff"}
              tilevalue={kFormatterWithDecimal(0)}
              tiletext="Current Resource Pool (FTE)"
            />
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <BacklogTable
              wb={wb}
              BTFilterObject={dropDownFilters}
              tableName="Backlog Solution Information"
            />
          </div>
        </div>
      </div>
    </>
  );
};
