import React from 'react'
import * as XLSX from "xlsx";
import * as d3 from "d3";
import "./tableComponent.css";

const BacklogTable = (props) => {
    const { wb, BTFilterObject, tableName } = props;

    var chartWidth = "100px";
    // percent = d3.format("0%");

    // var data = [
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 1, "Estimsted 3Y Return": 14432, "Development Cost $": 3454, "Annual Operation Cost": 1.0000, "3Y Net Return": 122, "Deveolpment Effort (Weeks)": 4 },
    //     { Approve: true, "Solution Name": "upload   ", "Prioritization": 4, "Estimsted 3Y Return": 24432, "Development Cost $": 6454, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 9 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 2, "Estimsted 3Y Return": 54432, "Development Cost $": 454, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 4 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 0, "Estimsted 3Y Return": 84432, "Development Cost $": 554, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 7 },
    //     { Approve: false, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 7432, "Development Cost $": 9454, "Annual Operation Cost": 1.0000, "3Y Net Return": 12, "Deveolpment Effort (Weeks)": 4 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 45432, "Development Cost $": 3454, "Annual Operation Cost": 1.0000, "3Y Net Return": 732, "Deveolpment Effort (Weeks)": 5 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 1, "Estimsted 3Y Return": 23432, "Development Cost $": 654, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 4 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 4, "Estimsted 3Y Return": 12432, "Development Cost $": 4254, "Annual Operation Cost": 1.0000, "3Y Net Return": 132, "Deveolpment Effort (Weeks)": 3 },
    //     { Approve: true, "Solution Name": "Credit", "Prioritization": 0, "Estimsted 3Y Return": 84432, "Development Cost $": 554, "Annual Operation Cost": 1.0000, "3Y Net Return": 232, "Deveolpment Effort (Weeks)": 7 },

    // ];

    if (
        !(
            wb &&
            Object.keys(wb).length === 0 &&
            Object.getPrototypeOf(wb) === Object.prototype
        )
    ) {
        var data = automationBacklogPrioritizationGrid(wb, BTFilterObject.filterObject);
        debugger;
        drawtableChart(data, chartWidth, "backlogGrid")
    }

    function drawtableChart(data, chartWidth, containerId) {
        var totalEstimate = d3.sum(data, function (d, i) {
            return d['EstThreeYearReturn'];
        });

        var totalDevelopment = d3.sum(data, function (d, i) {
            return d['EstDevelopmentCost'];
        });

        var totalAO = d3.sum(data, function (d, i) {
            return d['EstAnnualOpsCost'];
        });

        var total3YR = d3.sum(data, function (d, i) {
            return d['NetThreeYearReturn'];
        });

        var totalDevelopmentF = d3.sum(data, function (d, i) {
            return d['EstDevelopmentEffortWks'];
        });

        // Sort the data in descending order
        // data.sort(function (a, b) { return d3.descending(a[1], b[1]) });

        // Setup the scale for the values for display, use abs max as max value
        var x = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['EstThreeYearReturn']);
            })
            ])
            .range(["0%", "100%"]);

        var x1 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['EstDevelopmentCost']);
            })
            ])
            .range(["0%", "100%"]);

        var x2 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['NetThreeYearReturn']);
            })
            ])
            .range(["0%", "100%"]);

        var x3 = d3.scaleLinear()
            .domain([0, d3.max(data, function (d, i) {
                return Math.abs(d['EstDevelopmentEffortWks']);
            })
            ])
            .range(["0%", "100%"]);

        d3.select(".classTable").remove();

        var table = d3.select("#" + containerId).append("table")

            .attr("class", "  classTable")
            .attr("width", "100%")
            .attr("height", "300")
            .style("font-size", "12px")
            .style("font-family", '"Inter", sans-serif');
        //    if( window.innerWidth>666)
        var totalRow = table.append("tr");
        if (window.innerWidth > 714)
            totalRow.append("th").attr("class", "totalDesc").text('Approved');
        totalRow.append("th").attr("class", "totalDesc").text('Solution Name')
        if (window.innerWidth > 714)
            totalRow.append("th").attr("class", "totalDesc").text('Prioritization')
        totalRow.append("th").attr("class", "totalDesc").text('Estimated 3Y Return')
        totalRow.append("th").attr("class", "totalDesc").text('Development Cost $')
        totalRow.append("th").attr("class", "totalDesc").text('Annual Operational Cost')
        totalRow.append("th").attr("class", "totalDesc").text('3Y Net Return')
        totalRow.append("th").attr("class", "totalDesc").text('Development Effort (Weeks)')



        // Create a table with rows and bind a data row to each table row
        var tr = table.selectAll("tr.data")
            .data(data)
            .enter()
            .append("tr")
            .attr("class", "datarow");

        // Set the even columns
        table.selectAll(".datarow").filter(":nth-child(odd)").attr("class", "datarow odd")

        // Create the name column
        if (window.innerWidth > 714) {
            tr.append("td").attr("class", "data name")
                .append("svg")
                .attr("width", "50px")
                .attr("height", "20px")
                .append("path")
                .attr("d", function (d, i) {
                    return d['Approved for Development'] == "Yes" ? "M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" : ""
                })
        }


        tr.append("td").attr("class", "data name dataRightPadding")
            .text(function (d, i) {
                return d['Name']
            })
        if (window.innerWidth > 714) {
            tr.append("td").attr("class", "data name")
                .style("text-align", "right")
                .style("padding-right", "10px")
                // .attr("class","bi bi-reception-3")
                .append("svg")
                .attr("width", 16)
                .attr("height", 16)
                .style("fill", "currentColor")
                .attr("class", function (d, i) {
                    if (d['PrioritisationScore'] == '') return "bi bi-reception-0"
                    if (d['PrioritisationScore'] == 1) return "bi bi-reception-1"
                    if (d['PrioritisationScore'] == 2) return "bi bi-reception-2"
                    if (d['PrioritisationScore'] == 3) return "bi bi-reception-3"
                    if (d['PrioritisationScore'] == 4) return "bi bi-reception-4"
                })
                .append("path")
                .attr("d", function (d, i) {
                    if (d['PrioritisationScore'] == '') return ""
                    if (d['PrioritisationScore'] == 3) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['PrioritisationScore'] == 2) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4 5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['PrioritisationScore'] == 1) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    if (d['PrioritisationScore'] == 4) return "M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z"
                })
        }
        // Create a column at the beginning of the table for the chart
        var chart = tr.append("td").attr("class", "chart")
            .attr("width", chartWidth)

        // Create the div structure of the chart 
        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) { return "$" + (d['EstThreeYearReturn']) })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive barLeftToRight");

        // Creates the positive div bar
        tr.select("div.positive")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x(d['EstThreeYearReturn'])
            })

        chart = tr.append("td")
            .attr("class", "chart ")
            .attr("width", chartWidth)

        chart.append("div")
            .style("height", "100%")
            .attr("class", "container1 tdRightBorder rightBarText")
            .text(function (d, i) {
                return "$" + (d['EstDevelopmentCost'])
            })
            .style("text-align", "end")
            .append("div")
            .style("height", "100%")
            .style("z-index", -1)
            .attr("class", "positive1  barRightToLeft");

        // Creates the positive div bar
        tr.select("div.positive1")
            // .style("text-align", "end")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x1(d['EstDevelopmentCost'])
            })

            .style("right", "0")

        tr.append("td")
            .attr("width", chartWidth)
            .text(function (d, i) {
                return "$" + d3.format(".4f")(d['EstAnnualOpsCost'])
            })
            .style("text-align", "right")
            .style("padding-top" , 5)

        chart = tr.append("td")
            .attr("class", "chart")
            .attr("width", chartWidth)


        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) {
                return "$" + (d['NetThreeYearReturn'])
            })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive3 barLeftToRight");

        tr.select("div.positive3")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x2(d['NetThreeYearReturn'])
            })

        chart = tr.append("td")
            .attr("class", "chart")
            .attr("width", chartWidth)


        chart.append("div")
            .attr("class", "container tdLeftBorder rightBarText")
            .text(function (d, i) {
                return (d['EstDevelopmentEffortWks'])
            })
            .style("text-align", "right")
            .append("div")
            .style("height", "100%")
            .attr("class", "positive4 barLeftToRight");

        tr.select("div.positive4")
            .style("width", "0")
            .transition()
            .duration(500)
            .style("width", function (d, i) {
                return x3(d['EstDevelopmentEffortWks'])
            })

        var totalTr = table.append("tr")
            .attr("class", "bordered");
        // Add the Total row
        totalTr.append("td")
            .attr("class", "totalDesc")
            .text("Total")
        if (window.innerWidth > 714) {
            totalTr.append("td")
                .attr("class", "totalDesc")
                .text("");

            totalTr.append("td")
                .attr("class", "totalDesc")
                .text("");
        }
        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + totalEstimate))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + totalDevelopment))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text("$" + totalAO)
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text(("$" + total3YR))
            .style("text-align", "right")

        totalTr.append("td")
            .attr("class", "totalDesc")
            .text((totalDevelopmentF))
            .style("text-align", "right")
    }

    function automationBacklogPrioritizationGrid(workbookData, filterObject){
        var data = [];

        if (Object.keys(workbookData).length > 0) {

            var factSolutionSheet = workbookData.Sheets['FactSolution'];
            var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
                raw: true, defval: "",
                blankRows: false,
            });

            var filteredSheetData = sheetData;

            //filter fact solution sheet based on selected filters
            if (filterObject.BusinessUnit !== 0) {
                filteredSheetData = filteredSheetData.filter(ele => {
                    return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
                })
            }
            if (filterObject.Portfolio !== 0) {
                filteredSheetData = filteredSheetData.filter(ele => {
                    return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
                })
            }
            if (filterObject.IdeaStatusKey !== 0) {
                filteredSheetData = filteredSheetData.filter(ele => {
                    return ele.IdeaStatusKey !== "" && ele.IdeaStatusKey === filterObject.IdeaStatusKey;
                })
            }
            if (filterObject.ApprovalKey !== 0) {
                filteredSheetData = filteredSheetData.filter(ele => {
                    return ele.ApprovalKey !== "" && ele.ApprovalKey === filterObject.ApprovalKey;
                })
            }

            //DimApproval Sheet data
            var dimApprovalSheet = workbookData.Sheets['DimApproval'];
            var dimApprovalData = XLSX.utils.sheet_to_json(dimApprovalSheet, {
                raw: true, defval: "",
                blankRows: false,
            });

            //filter fact solution sheet records based upon their Approval Status
            //Calculate NetThreeYearReturn 
            var mappedData = [];
            dimApprovalData.forEach(dimApproval => {
                filteredSheetData.forEach(factSolution => {
                    if (factSolution.ApprovalKey === dimApproval.ApprovalKey) {

                        factSolution.EstThreeYearReturn = factSolution.EstThreeYearReturn === "" ? 0 : factSolution.EstThreeYearReturn;
                        factSolution.EstAnnualOpsCost = factSolution.EstAnnualOpsCost === "" ? 0 : factSolution.EstAnnualOpsCost;
                        factSolution.EstDevelopmentCost = factSolution.EstDevelopmentCost === "" ? 0 : factSolution.EstDevelopmentCost;
                        factSolution.EstDevelopmentEffortWks = factSolution.EstDevelopmentEffortWks == "" ? 0 : factSolution.EstDevelopmentEffortWks;

                        var sumOfAnnualOpsCostandDevCost = (factSolution.EstAnnualOpsCost*3) + factSolution.EstDevelopmentCost;

                        if(sumOfAnnualOpsCostandDevCost > factSolution.EstThreeYearReturn){
                            factSolution.NetThreeYearReturn = sumOfAnnualOpsCostandDevCost - factSolution.EstThreeYearReturn;
                        }
                        else{
                            factSolution.NetThreeYearReturn = factSolution.EstThreeYearReturn - sumOfAnnualOpsCostandDevCost;
                        }

                        factSolution["Approved for Development"] = dimApproval["Approved for Development"];
                        mappedData.push(factSolution);
                    }
                })
            })

            data = mappedData
        }
        console.log(data);
        return data;
    }

    return (
        <>
            <div className="rounded my-1 py-1 mb-2" style={{ background: "#e1e1e1" }}>
                <h6 style={{ marginBottom: "0", textAlign: "center" }}>
                    {tableName}
                </h6>
            </div>
            <div id="backlogGrid" height="300px"></div>
        </>
    )
}

export default BacklogTable