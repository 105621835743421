import React from "react";
import * as XLSX from "xlsx";

import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

var stackBarData;

const StackBarChart = (props) => {
  const { wb, SBFilterObject } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);
  stackBarData = {
    layout: {
      barmode: "stack",
      height: 190,
      font: {
        size: 12,
        family: '"Inter", sans-serif',
      },
      legend: {
        x: -0.5,
        y: 10,
        title: { text: "   <b>Health Order</b>" },
      },
      margin: { 'pad': 5 , l: 0, r: 0, b: 40, t: 0 },
      xaxis: {
        zeroline: false,
        showgrid: false,
        showticklabels: false,
      },
      yaxis: {
        showticklabels: true,
        title: {
          text: "<b>Technology</b>",
        },
        
      },
    },

    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true
    },
  };

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var data = getHOStackedBarsDataForDetailDashboard(
      wb,
      SBFilterObject.filterObject
    );
    var filteredData = data.map((el) => {
      var color = 'rgb( 0 89 255)';
      if(el.name === "Blank"){
        color = 'rgb(230, 230, 230)';
      }
      return {
        x: el.y,
        y: el.x,
        text: el.y,
        name: el.name,
        orientation: "h",
        marker: {
          color: color,
          width: 1,
        },
        type: "bar",
        textposition: "inside",
      };
    });
    stackBarData.data = filteredData;
  }



  function getHOStackedBarsDataForDetailDashboard(workbookData, filterObject) {
    var data = [];

    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey !== "" &&
            ele.BusinessUnitKey === filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey !== "" &&
            ele.StageKey === filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        });
      }

      //factQueue Sheet data
      var factQueueSheet = workbookData.Sheets["FactQueue"];
      var factQueueData = XLSX.utils.sheet_to_json(factQueueSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      //map data with Fact Solution sheet for Applying filters
      var mappedDataWithFactSolSheet = [];
      factQueueData.forEach((factQ) => {
        filteredSheetData.forEach((factSol) => {
          if (factQ["Unique Identifier"] === factSol["UniqueIdentifier"]) {
            mappedDataWithFactSolSheet.push(factQ);
          }
        });
      });

      //dimTechnology Sheet data
      var dimTechnologySheet = workbookData.Sheets["DimTechnology"];
      var dimTechnologyData = XLSX.utils.sheet_to_json(dimTechnologySheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      //map data with DimTechnology sheet for getting technology names
      var mappedDataWithTechSheet = [];
      dimTechnologyData.forEach((tech) => {
        mappedDataWithFactSolSheet.forEach((factQ) => {
          if (tech.TechnologyKey === factQ.TechnologyKey) {
            factQ.Technology = tech.Technology;
            mappedDataWithTechSheet.push(factQ);
          }
        });
      });

      //dimHealth Sheet data
      var dimHealthSheet = workbookData.Sheets["DimHealth"];
      var dimHealthData = XLSX.utils.sheet_to_json(dimHealthSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      //map data with DimHealth sheet for getting Health Order
      var mappedDataOnHealthOrder = {};
      dimHealthData.forEach((health) => {
        mappedDataWithTechSheet.forEach((fact) => {
          if (health.HealthKey === fact.HealthKey) {
            fact["Health Order"] = health["Health Order"];
            if (mappedDataOnHealthOrder[fact["Health Order"]] === undefined) {
              mappedDataOnHealthOrder[fact["Health Order"]] = [];
            }
            mappedDataOnHealthOrder[fact["Health Order"]].push(fact);
          } else if (fact.HealthKey === "") {
            fact["Health Order"] = "Blank";
            if (mappedDataOnHealthOrder[fact["Health Order"]] === undefined) {
              mappedDataOnHealthOrder[fact["Health Order"]] = [];
            }
            if (
              mappedDataOnHealthOrder[fact["Health Order"]].indexOf(fact) === -1
            ) {
              mappedDataOnHealthOrder[fact["Health Order"]].push(fact);
            }
          }
        });
      });

      Object.keys(mappedDataOnHealthOrder).forEach((element) => {
        var currentHOChildrens = mappedDataOnHealthOrder[element];
        var similarTechCount = currentHOChildrens.reduce(
          (acc, o) => (
            (acc[o["Technology"]] = (acc[o["Technology"]] || 0) + 1), acc
          ),
          {}
        );

        data.push({
          name: element,
          x: Object.keys(similarTechCount),
          y: Object.values(similarTechCount)
        });
      });
    }
    return data;
  }

  return (
    <>
      <div className="h-30 rounded">
        <PlotlyComponent
          style={{ width: "100%" }}
          data={stackBarData.data}
          layout={stackBarData.layout}
          config={stackBarData.config}
        />
      </div>
    </>
  );
};

export default StackBarChart;
