
import React from "react";
import { useHistory } from "react-router-dom";
import { HomeIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Button, Breadcrumb } from 'react-bootstrap';

import { ideas, IdeasTable, PageTrafficTable, RankingTable } from "components/Tables";
import { Routes } from "routes";


export default () => {
  const history = useHistory();

  const goToTablesDocs = () => {
    history.push(Routes.Tables.path);
  };

  return (
    <>
      <div className="py-4">
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
          <Breadcrumb.Item>Tables</Breadcrumb.Item>
          <Breadcrumb.Item active>Ideas</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mb-3 mb-lg-0">
            <h4>Ideas</h4>
            <p className="mb-0">
            Quickly catpure ideas and undertand their respective benefits 
            </p>
          </div>
        </div>
      </div>

      <IdeasTable />
      {/* <RankingTable /> */}
    </>
  );
};
