import React from "react";
import * as XLSX from "xlsx";
import * as d3 from "d3";
import "./tableComponent.css";
import { Table } from "react-bootstrap";
import { ChevronDownIcon } from "@heroicons/react/solid";

const DetailedTable = (props) => {
  const { wb, DTFilterObject, tableName } = props;

  var chartWidth = "90px";

  if (!(wb && Object.keys(wb).length === 0 && Object.getPrototypeOf(wb) === Object.prototype)) {
    var filteredData = getTableChartDataForDetailDashboard(
      wb,
      DTFilterObject.filterObject
    );
    detailedGrid(filteredData, chartWidth, "detailedGrid");
  }

  function detailedGrid(data, chartWidth, containerId) {
    var totalEstimate = d3.sum(data, function (d, i) {
      return d["Actual Volume"];
    });

    var totalDevelopment = d3.sum(data, function (d, i) {
      return d["Expected Volume"];
    });

    var totalAO = d3.sum(data, function (d, i) {
      return d["Actual Benefit"];
    });

    var total3YR = d3.sum(data, function (d, i) {
      return d["Expected Annualised Benefit"];
    });

    // Sort the data in descending order
    // data.sort(function (a, b) { return d3.descending(a[1], b[1]) });

    // Setup the scale for the values for display, use abs max as max value
    var x = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d, i) {
          return Math.abs(d["Actual Volume"]);
        }),
      ])
      .range(["0%", "100%"]);

    var x1 = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d, i) {
          return Math.abs(d["Expected Volume"]);
        }),
      ])
      .range(["0%", "100%"]);

    var x2 = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d, i) {
          return Math.abs(d["Actual Benefit"]);
        }),
      ])
      .range(["0%", "100%"]);

    var x3 = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d, i) {
          return Math.abs(d["Expected Annualised Benefit"]);
        }),
      ])
      .range(["0%", "100%"]);

    d3.select(".classTable").remove();

    var table = d3
      .select("#" + containerId)
      .append("table")
      .attr("class", "classTable")
      .attr("width", "100%")
      .attr("height", "100")
      .style("font-size", "12px")
      .style("font-family", '"Inter", sans-serif');
    var totalRow = table.append("tr");
    //   639  
    if (window.innerWidth > 666)
      //  if(document.getElementById('detailedGrid').offsetWidth>558) 
      totalRow.append("th").attr("class", "totalDesc").text(".");
    totalRow.append("th").attr("class", "totalDesc").text("Solution Name");

    if (window.innerWidth > 666)
    // if(document.getElementById('detailedGrid').offsetWidth>558) 
    {
      totalRow.append("th").attr("class", "totalDesc").text("Queue");
      totalRow.append("th").attr("class", "totalDesc").text("Technology");
    }
    totalRow.append("th").attr("class", "totalDesc").text("Actual Volume");
    totalRow.append("th").attr("class", "totalDesc").text("Expected Volume");
    totalRow.append("th").attr("class", "totalDesc").text("Actual Benefit");
    totalRow
      .append("th")
      .attr("class", "totalDesc")
      .text("Expected Annualised Benefit");

    // Create a table with rows and bind a data row to each table row
    var tr = table
      .selectAll("tr.data")
      .data(data)
      .enter()
      .append("tr")
      .attr("class", "datarow");

    // Set the even columns
    table
      .selectAll(".datarow")
      .filter(":nth-child(odd)")
      .attr("class", "datarow odd");

    // Create the name column
    // if(document.getElementById('detailedGrid').offsetWidth>558)
    if (window.innerWidth > 666) {
      tr.append("td")
        .attr("class", "data name")
        .style("float", "center")
        .style("padding-left", "10px")
        .append("svg")
        .attr("width", 10)
        .attr("height", 10)
        .append("circle")
        .attr("cx", 5)
        .attr("cy", 5)
        .attr("r", 3)
    }
    tr.append("td")
      .attr("class", "data name  ")
      .attr("title", function (d, i) {
        return d["Solution Name"];
      })
      .text(function (d, i) {
        return d["Solution Name"];
      });

    // if(document.getElementById('detailedGrid').offsetWidth>558)
    if (window.innerWidth > 666) {
      tr.append("td")
        .attr("class", "data name ")
        .style("float", "right")
        .attr("title", function (d, i) {
          return d["Queue Display"];
        })
        .text(function (d, i) {
          return d["Queue Display"];
        })
        .style("anchor-text", "middle");

      tr.append("td")
        .attr("class", "data name ")
        .attr("title", function (d, i) {
          return d["TechnologyName"];
        })
        .text(function (d, i) {
          return d["TechnologyName"];
        });
    }
    // Create a column at the beginning of the table for the chart
    var chart = tr
      .append("td")
      .attr("class", "chart")
      .attr("width", chartWidth);

    // Create the div structure of the chart
    chart
      .append("div")
      .attr("class", "container tdLeftBorder rightBarText divClass")
      .style("float", "center")
      .text(function (d, i) {
        if (d["Actual Volume"] == "")
          return "";
        else
          return "$" + d["Actual Volume"];
      })
      .style("text-align", "right")
      .append("div")
      .attr("class", "positive barLeftToRight divClass");

    // Creates the positive div bar
    tr.select("div.positive")
      .style("width", "0")
      .transition()
      .duration(500)
      .style("width", function (d, i) {
        return x(d["Actual Volume"]);
      });

    chart = tr.append("td").attr("class", "chart").attr("width", chartWidth);
    chart
      .append("div")
      .attr("class", "container tdLeftBorder rightBarText divClass")
      .text(function (d, i) {
        if (d["Expected Volume"] == "")
          return "";
        else
          return "$" + d["Expected Volume"];
      })
      .style("text-align", "right")
      .style("float", "end")
      .append("div")
      .attr("class", "positive1 barLeftToRight divClass");

    tr.select("div.positive1")
      .style("width", "0")
      .transition()
      .duration(500)
      .style("width", function (d, i) {
        return x1(d["Expected Volume"]);
      });

    chart = tr.append("td").attr("class", "chart").attr("width", chartWidth);
    chart
      .append("div")
      .attr("class", "container tdLeftBorder rightBarText divClass")
      .text(function (d, i) {
        if (d["Actual Benefit"] == "")
          return "";
        else
          return "$" + d["Actual Benefit"];
      })
      .style("text-align", "right")
      .append("div")
      .attr("class", "positive3 barLeftToRight divClass");

    tr.select("div.positive3")
      .style("width", "0")
      .transition()
      .duration(500)
      .style("width", function (d, i) {
        return x2(d["Actual Benefit"]);
      });

    chart = tr.append("td").attr("class", "chart").attr("width", chartWidth);

    chart
      .append("div")
      .attr("class", "container tdLeftBorder rightBarText divClass")
      .text(function (d, i) {
        if (d["Expected Annualised Benefit"] == "")
          return "";
        else
          return d["Expected Annualised Benefit"];
      })
      .style("text-align", "right")
      .append("div")
      .attr("class", "positive4 barLeftToRight divClass");

    tr.select("div.positive4")
      .style("width", "0")
      .transition()
      .duration(500)
      .style("width", function (d, i) {
        return x3(d["Expected Annualised Benefit"]);
      });

    var totalTr = table.append("tr").attr("class", "bordered");
    // Add the Total row
    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("Total");

    //if(document.getElementById('detailedGrid').offsetWidth>558)
    if (window.innerWidth > 666) {
      totalTr.append("td").attr("class", "totalDesc").text("");

      totalTr.append("td").attr("class", "totalDesc").text("");
      totalTr.append("td").attr("class", "totalDesc").text("");
    }

    var formatter = d3.format(",.2f");
    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + formatter(totalEstimate))
      .style("text-align", "right");

    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + formatter(totalDevelopment))
      .style("text-align", "right");

    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + formatter(totalAO))
      .style("text-align", "right");

    totalTr
      .append("td")
      .attr("class", "totalDesc")
      .text("$" + formatter(total3YR))
      .style("text-align", "right");
  }

  // filtered Table ChartData Method
  function getTableChartDataForDetailDashboard(workbookData, filterObject) {
    var data = [];

    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey != "" &&
            ele.BusinessUnitKey == filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey != "" && ele.PortfolioKey == filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment != 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey != "" &&
            ele.StageKey == filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName != "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name != "" && ele.Name == filterObject.SolutionsName;
        });
      }

      //dimTechnology Sheet data
      var dimTechnologySheet = workbookData.Sheets["DimTechnology"];
      var dimTechnologyData = XLSX.utils.sheet_to_json(dimTechnologySheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      //factQueue Sheet data
      var factQueueSheet = workbookData.Sheets["FactQueue"];
      var factQueueData = XLSX.utils.sheet_to_json(factQueueSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var mappedFactQueueData = [];
      dimTechnologyData.forEach((tech) => {
        factQueueData.forEach((fact) => {
          if (fact.TechnologyKey == tech.TechnologyKey) {
            fact.TechnologyName = tech.Technology;
            mappedFactQueueData.push(fact);
          }
        });
      });

      var dataWithFilters = [];
      mappedFactQueueData.forEach((factQueueElement) => {
        filteredSheetData.forEach((factSolElement) => {
          if (
            factSolElement["UniqueIdentifier"] ==
            factQueueElement["Unique Identifier"]
          ) {
            factQueueElement["Solution Name"] = factSolElement.Name;
            dataWithFilters.push(factQueueElement);
          }
        });
      });
      data = dataWithFilters;
    }
    return data;
  }

  return (
    <>
      <div className="rounded mt-2 mb-3 py-1" style={{ background: "#e1e1e1" }}>
        <h6 style={{ marginBottom: "0", textAlign: "center" }}>
          {tableName}
          <a class="info" style={{
            border: '1px solid #000',
            borderRadius: '50px',
            fontSize: '9px',
            margin: 0,
            fontWeight: 'bold',
            padding: '0px 4px',
            color: 'black',
            verticalAlign: 'middle',
            marginLeft: '5px'
          }}>i</a>
        </h6>
      </div>
      <div id="detailedGrid" height="300px"></div>
    </>
  );
};

export default DetailedTable;
