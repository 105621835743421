import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import $ from "jquery";
// import {
//   CloudUploadIcon,
//   CollectionIcon,
//   FireIcon,
//   PlusIcon,
//   ShieldExclamationIcon,
//   UserAddIcon,
// } from "@heroicons/react/solid";
// import { Col, Row, Button, Dropdown } from "react-bootstrap";

// import {
//   CustomersWidget,
//   RevenueWidget,
//   UsersWidget,
//   WeeklyReportWidget,
//   TopAuthorsWidget,
//   TeamMembersWidget,
//   ProgressTrackWidget,
//   EventsWidget,
//   RankingWidget,
//   VisitsMapWidget,
//   SalesValueWidget,
//   AcquisitionWidget,
//   TimelineWidget,
// } from "components/Widgets";
// import { PageVisitsTable } from "components/Tables";
import Tiles from "pages/dashboardcomponents/Tiles";
import BarChart from "pages/dashboardcomponents/BarChart";
import DonutChart from "pages/dashboardcomponents/DonutChart";
import WaterfallChart from "pages/dashboardcomponents/WaterfallChart";
import ActualvsExpectedBarChart from "pages/dashboardcomponents/ActualvsExpectedBarChart";

var filterObject = {
  BusinessUnit: 0,
  Portfolio: 0,
  StageOfDevelopment: 0,
  SolutionsName: "",
};

var isChangeCalled = false;

export default () => {
  const [wb, setWb] = useState({});
  const [dropDownFilters, setDropDownFilters] = useState({ filterObject });
  const [year, setYear] = useState("2022");
  const [wfWidth, setWFWidth] = useState(0);

  var workbook;
  useEffect(() => {
    if (
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    ) {
      importExcel();
      function importExcel() {
        makeRequest(
          "GET",
          "./olap_dummy_data.xlsx",
          // success
          function (data) {
            workbook = convertDataToWorkbook(data);
            if (document.querySelector(".wfContainer") != null) {
              var width = document.querySelector(".wfContainer").offsetWidth;
              setWFWidth(width);
            }
            setWb(workbook);
          },
          // error
          function (error) {
            throw error;
          }
        );
      }
      function convertDataToWorkbook(data) {
        // convert data to binary string /
        var data = new Uint8Array(data);
        var arr = new Array();

        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }

        var bstr = arr.join("");

        return XLSX.read(bstr, { type: "binary" });
      }
      // XMLHttpRequest in promise format
      function makeRequest(method, url, success, error) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.open("GET", url, true);
        httpRequest.responseType = "arraybuffer";

        httpRequest.open(method, url);
        httpRequest.onload = function () {
          success(httpRequest.response);
        };
        httpRequest.onerror = function () {
          error(httpRequest.response);
        };
        httpRequest.send();
      }

      function handleResize() {
        if (document.querySelector(".wfContainer") != null) {
          var width = document.querySelector(".wfContainer").offsetWidth;
          setWFWidth(width);
        }
      }

      window.addEventListener('resize', handleResize)
    }
    return;
  }, [wb, wfWidth]);

  var buOption = "";
  var PortfolioOption = "";
  var StageOption = "";
  var SolutionNameOption = "";

  var padding = "4px";

  var filterDropdownsData = generateFilterDropdownsData(wb, filterObject);

  const loadDropdownsDefaultData = (filterDropdownsData) => {
    if (
      Object.keys(filterDropdownsData).length > 0 &&
      isChangeCalled === false
    ) {
      // Business Unit Dropdown
      buOption = "<option value='0'>All</option>";
      filterDropdownsData["Business Unit"].forEach((element) => {
        buOption += `<option value= ${element.BusinessUnitKey} > ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(buOption);

      // Portfolio Dropdown
      PortfolioOption = "<option value='0'>All</option>";
      filterDropdownsData["Team"].forEach((element) => {
        PortfolioOption += `<option value= ${element.PortfolioKey} > ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOption);

      // Stage of Development Dropdown
      StageOption = "<option value='0'>All</option>";
      filterDropdownsData["Stage of Development"].forEach((element) => {
        StageOption += `<option value= "${element.StageKey}" > ${element["Stage of Development Order"]} </option>`;
      });
      $("#stageOfDevKey").empty().append(StageOption);
      // Solution Name Dropdown
      SolutionNameOption = "<option value='0'>All</option>";
      filterDropdownsData["Solution Name"].forEach((element) => {
        SolutionNameOption += `<option value= "${element}" > ${element} </option>`;
      });
      $("#solutionNameKey").empty().append(SolutionNameOption);
    }
  };

  loadDropdownsDefaultData(filterDropdownsData);

  function generateFilterDropdownsData(workbookData, filterObject) {
    var objFilteredDropdwonsData = {};
    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey !== "" &&
            ele.BusinessUnitKey === filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey !== "" &&
            ele.StageKey === filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        });
      }
      if (
        filterObject.BusinessUnit === 0 &&
        filterObject.Portfolio === 0 &&
        filterObject.StageOfDevelopment === 0 &&
        filterObject.SolutionsName === ""
      ) {
        filteredSheetData = filteredSheetData;
      }

      var bussinessUnitKeys = filteredSheetData.map((ele) => {
        return ele.BusinessUnitKey;
      });

      var uniqueBusinessUnitKeys = bussinessUnitKeys.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      //DimBU Sheet data
      var dimBUSheet = workbookData.Sheets["DimBU"];
      var dimBUData = XLSX.utils.sheet_to_json(dimBUSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filterBusinessUnitKeys = [];
      uniqueBusinessUnitKeys.forEach((el) => {
        dimBUData.forEach((element) => {
          if (element.BusinessUnitKey === el) {
            filterBusinessUnitKeys.push(element);
          }
        });
      });

      objFilteredDropdwonsData["Business Unit"] = filterBusinessUnitKeys;

      var portfolioKeys = filteredSheetData.map((ele) => {
        return ele.PortfolioKey;
      });

      var uniquePortfolioKeys = portfolioKeys.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      //Portfolio Sheet data
      var dimPortfolioSheet = workbookData.Sheets["DimPortfolio"];
      var dimPortfolioData = XLSX.utils.sheet_to_json(dimPortfolioSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filterPortfolios = [];
      uniquePortfolioKeys.forEach((el) => {
        dimPortfolioData.forEach((element) => {
          if (element.PortfolioKey === el) {
            filterPortfolios.push(element);
          }
        });
      });

      objFilteredDropdwonsData["Team"] = filterPortfolios;

      //Portfolio Sheet data
      var dimStageSheet = workbookData.Sheets["DimStage"];
      var dimStageData = XLSX.utils.sheet_to_json(dimStageSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      objFilteredDropdwonsData["Stage of Development"] = dimStageData;

      var solutionNames = filteredSheetData.map((ele) => {
        return ele.Name;
      });

      var uniqueSolutionNames = solutionNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      objFilteredDropdwonsData["Solution Name"] = uniqueSolutionNames;
    }
    return objFilteredDropdwonsData;
  }

  // Business Unit Onchange method
  const handleBUDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt(e.target.value);
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.StageOfDevelopment = parseInt($("#stageOfDevKey").val());
    filterObject.SolutionsName =
      $("#solutionNameKey").val() === "0" ? "" : $("#solutionNameKey").val();

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = generateFilterDropdownsData(wb, filterObject);

      // Portfolio Dropdown
      PortfolioOption = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOption += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOption);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Stage of Development Dropdown
      StageOption = "<option value='0'>All</option>";

      filteredDropdownsData["Stage of Development"].forEach((element) => {
        StageOption += `<option value= "${element.StageKey}" > ${element["Stage of Development Order"]} </option>`;
      });
      $("#stageOfDevKey").empty().append(StageOption);
      $("#stageOfDevKey").val(filterObject.StageOfDevelopment);

      // Solution Name Dropdown
      SolutionNameOption = "<option value='0'>All</option>";

      filteredDropdownsData["Solution Name"].forEach((element) => {
        SolutionNameOption += `<option value= "${element}" > ${element} </option>`;
      });
      $("#solutionNameKey").empty().append(SolutionNameOption);
      var solutionName =
        filterObject.SolutionsName === "" ? 0 : filterObject.SolutionsName;
      $("#solutionNameKey").val(solutionName);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };
  // PortFolio Onchange method
  const handlePFDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt(e.target.value);
    filterObject.StageOfDevelopment = parseInt($("#stageOfDevKey").val());
    filterObject.SolutionsName =
      $("#solutionNameKey").val() === "0" ? "" : $("#solutionNameKey").val();

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = generateFilterDropdownsData(wb, filterObject);

      // Portfolio Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);

      // Stage of Development Dropdown
      var StageOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Stage of Development"].forEach((element) => {
        StageOptions += `<option value= "${element.StageKey}" > ${element["Stage of Development Order"]} </option>`;
      });
      $("#stageOfDevKey").empty().append(StageOptions);
      $("#stageOfDevKey").val(filterObject.StageOfDevelopment);

      // Solution Name Dropdown
      var SolutionNameOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Solution Name"].forEach((element) => {
        SolutionNameOptions += `<option value= "${element}" > ${element} </option>`;
      });
      $("#solutionNameKey").empty().append(SolutionNameOptions);
      var solutionName =
        filterObject.SolutionsName === "" ? 0 : filterObject.SolutionsName;
      $("#solutionNameKey").val(solutionName);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };
  // Stage of Dev Onchange method
  const handleSDDropdownChange = (e) => {
    //-------------
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.StageOfDevelopment = parseInt(e.target.value);
    filterObject.SolutionsName =
      $("#solutionNameKey").val() === "0" ? "" : $("#solutionNameKey").val();

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = generateFilterDropdownsData(wb, filterObject);

      // Business Unit Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);

      // Portfolio Dropdown
      var PortfolioOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOptions += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOptions);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Solution Name Dropdown
      var SolutionNameOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Solution Name"].forEach((element) => {
        SolutionNameOptions += `<option value="${element}" > ${element} </option>`;
      });
      $("#solutionNameKey").empty().append(SolutionNameOptions);
      var solutionName =
        filterObject.SolutionsName === "" ? 0 : filterObject.SolutionsName;
      $("#solutionNameKey").val(solutionName);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };
  // Solution Names Onchange method
  const handleSNDropdownChange = (e) => {
    filterObject.BusinessUnit = parseInt($("#businessUnitKey").val());
    filterObject.Portfolio = parseInt($("#portFolioKey").val());
    filterObject.StageOfDevelopment = parseInt($("#stageOfDevKey").val());
    filterObject.SolutionsName = e.target.value === "0" ? "" : e.target.value;

    if (Object.keys(wb).length > 0) {
      isChangeCalled = true;
      var filteredDropdownsData = generateFilterDropdownsData(wb, filterObject);

      // Portfolio Dropdown
      var businessUnitsOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Business Unit"].forEach((element) => {
        businessUnitsOptions += `<option value= ${element.BusinessUnitKey}> ${element.BusinessUnit} </option>`;
      });
      $("#businessUnitKey").empty().append(businessUnitsOptions);
      $("#businessUnitKey").val(filterObject.BusinessUnit);

      // Portfolio Dropdown
      var PortfolioOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Team"].forEach((element) => {
        PortfolioOptions += `<option value= ${element.PortfolioKey}> ${element.Portfolio} </option>`;
      });
      $("#portFolioKey").empty().append(PortfolioOptions);
      $("#portFolioKey").val(filterObject.Portfolio);

      // Stage of Development Dropdown
      var StageOptions = "<option value='0'>All</option>";

      filteredDropdownsData["Stage of Development"].forEach((element) => {
        StageOptions += `<option value= "${element.StageKey}" > ${element["Stage of Development Order"]} </option>`;
      });
      $("#stageOfDevKey").empty().append(StageOptions);
      $("#stageOfDevKey").val(filterObject.StageOfDevelopment);
    }
    setDropDownFilters({ ...dropDownFilters, filterObject });
  };

  const onYearChange = (e) => {
    var value = e.target.value;
    // alert(value)
    setYear(value);
  };

  return (
    <>
      <div className="p-3 mt-5 rounded" style={{ backgroundColor: "white" }}>
        <div
          className="row rounded mx-0 mb-3 py-3 "
          style={{ background: "#e1e1e1" }}
        >
          <div className="col-md-3 ">
            <label>Portfolio</label>
            <select
              className="selectPF rounded"
              id="portFolioKey"
              onChange={handlePFDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3">
            <label>Business Unit</label>
            <select
              className="selectBU rounded"
              id="businessUnitKey"
              onChange={handleBUDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3 ">
            <label>Stage of Development</label>
            <select
              className="selectSD rounded"
              id="stageOfDevKey"
              onChange={handleSDDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
          <div className="col-md-3 ">
            <label>Solution Name</label>
            <select
              className="selectSN rounded"
              id="solutionNameKey"
              onChange={handleSNDropdownChange}
              style={{
                height: "35px",
                width: "100%",
                outline: "none",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <option>All</option>
            </select>
          </div>
        </div>
        <div className="row position-relative">
          <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <Tiles wb={wb} tilesFilterObject={dropDownFilters} />
          </div>
          <select
            className=" my-2 me-3 position-absolute end-0 text-muted"
            onChange={onYearChange}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              width: '75px',
              fontSize: '12px',
              height: '23px'
            }}
          >
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option selected value="2022">
              2022
            </option>
          </select>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <DonutChart
              wb={wb}
              donutChartName="Solution Health Status"
              donutsFilterObject={dropDownFilters}
            />
            <DonutChart
              wb={wb}
              donutChartName="Solutions by Stage of Development"
              donutsFilterObject={dropDownFilters}
            />
            <DonutChart
              wb={wb}
              donutChartName="Solutions by Technology"
              donutsFilterObject={dropDownFilters}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <div
              className="rounded my-1"
              style={{
                background: "#e1e1e1",
                paddingTop: `${padding}`,
                paddingBottom: `${padding}`,
              }}
            >
              <h6 style={{ marginBottom: "0", textAlign: "center" }}>
                Solutions Released into production <a class="info" style={{
                  border: '1px solid #000',
                  borderRadius: '50px',
                  fontSize: '9px',
                  margin: 0,
                  fontWeight: 'bold',
                  padding: '0px 4px',
                  color: 'black'
                }}>i</a>
              </h6>
            </div>
            <div className="w-100 wfContainer" style={{ height: 190 }}>
              <WaterfallChart
                wb={wb}
                wfFilterObject={dropDownFilters}
                wfChartName={"Solutions Released into production"}
                year={year}
                WFHeight={190}
                WFWidth={wfWidth}
              />
            </div>
            <ActualvsExpectedBarChart
              wb={wb}
              ActualvsExpectedBarChartFilterObject={dropDownFilters}
              year={year}
            />
            <BarChart
              wb={wb}
              BarChartFilterObject={dropDownFilters}
              year={year}
            />
          </div>
        </div>
      </div>
    </>
  );
};
