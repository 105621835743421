import React from "react";
// import Plot from "react-plotly.js";
import {
  CloudUploadIcon,
  CollectionIcon,
  FireIcon,
  PlusIcon,
  ShieldExclamationIcon,
  UserAddIcon,
} from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown, ButtonGroup } from "react-bootstrap";

import { RankingTable } from "components/Tables";
import {
  BarChartWidget,
  RatingsWidget,
  CardWidget,
  ListChartWidget,
} from "components/Widgets";
import { appRanking } from "data/charts";
const Plot = "";

export default () => {
  return (
    <>
      <div className="py-5">
        <Plot
          data={[
            {
              colorscale: [
                [0.0, "#000004"],
                [0.1111111111111111, "#180f3d"],
                [0.2222222222222222, "#440f76"],
                [0.3333333333333333, "#721f81"],
                [0.4444444444444444, "#9e2f7f"],
                [0.5555555555555556, "#cd4071"],
                [0.6666666666666666, "#f1605d"],
                [0.7777777777777778, "#fd9668"],
                [0.8888888888888888, "#feca8d"],
                [1.0, "#fcfdbf"],
              ],
              reversescale: true,
              showscale: false,
              text: [
                [
                  "<br>P6: 24",
                  "<br>P147: 4<br>P148: 4<br>P138: 2<br>P152: 2<br>P109: 2<br>P150: 4<br>P151: 4<br>P146: 4<br>P8: 2<br>P139: 2<br>P149: 4<br>P110: 1<br>P131: 1<br>P136: 1<br>P137: 1",
                  "",
                  "",
                  "<br>P30: 2<br>P10: 2<br>P19: 1<br>P24: 2<br>P50: 3",
                  "<br>P3: 1",
                  "<br>P62: 4",
                  "",
                  "<br>P140: 1<br>P142: 1<br>P94: 2<br>P141: 1<br>P143: 2<br>P36: 1<br>P35: 1<br>P122: 1<br>P123: 1<br>P120: 1<br>P144: 2<br>P34: 1<br>P121: 1<br>P111: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P117: 2<br>P52: 2<br>P79: 8",
                  "<br>P41: 1",
                  "<br>P12: 1",
                  "<br>P54: 12",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P145: 10<br>P135: 15",
                  "",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1",
                  "<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P157: 33<br>P158: 38<br>P153: 36<br>P163: 12",
                  "<br>P146: 6<br>P151: 6<br>P137: 3<br>P150: 6<br>P139: 1<br>P148: 6<br>P136: 2<br>P110: 3<br>P149: 6<br>P147: 6<br>P131: 3<br>P152: 6<br>P138: 2<br>P109: 2<br>P8: 1<br>P169: 1",
                ],
                [
                  "<br>P6: 24",
                  "<br>P152: 4<br>P149: 2<br>P146: 2<br>P131: 2<br>P110: 2<br>P137: 2<br>P148: 2<br>P136: 2<br>P147: 2<br>P150: 2<br>P138: 2<br>P8: 2<br>P151: 2<br>P139: 2<br>P109: 1",
                  "",
                  "",
                  "<br>P30: 2<br>P10: 2<br>P19: 1<br>P24: 4",
                  "<br>P3: 1",
                  "<br>P26: 10",
                  "",
                  "<br>P94: 9<br>P141: 1<br>P140: 2<br>P36: 3<br>P122: 2<br>P123: 2<br>P120: 2<br>P111: 3<br>P34: 1<br>P143: 1<br>P142: 1<br>P121: 2<br>P35: 1<br>P144: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P117: 4<br>P52: 4<br>P79: 8",
                  "<br>P41: 1<br>P103: 15",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P145: 10<br>P135: 1",
                  "",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1",
                  "<br>P82: 10",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P163: 22<br>P158: 54<br>P153: 56<br>P157: 56",
                  "<br>P150: 6<br>P151: 6<br>P169: 1<br>P152: 6<br>P148: 6<br>P146: 6<br>P109: 3<br>P147: 6<br>P110: 2<br>P149: 6<br>P136: 2<br>P137: 2<br>P138: 2<br>P8: 3<br>P131: 1<br>P139: 3",
                ],
                [
                  "<br>P6: 24",
                  "<br>P148: 4<br>P139: 1<br>P110: 2<br>P149: 4<br>P152: 4<br>P150: 4<br>P147: 4<br>P137: 2<br>P146: 4<br>P131: 2<br>P8: 1<br>P138: 1<br>P151: 4<br>P109: 2<br>P136: 2",
                  "<br>P78: 15<br>P73: 5",
                  "",
                  "<br>P30: 2<br>P24: 4<br>P10: 1<br>P50: 3<br>P19: 3",
                  "<br>P100: 2<br>P99: 1",
                  "<br>P26: 10",
                  "",
                  "<br>P94: 3<br>P143: 2<br>P144: 3<br>P142: 2<br>P123: 1<br>P35: 2<br>P141: 2<br>P111: 1<br>P36: 1<br>P34: 3<br>P122: 1<br>P140: 2<br>P120: 1<br>P121: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P117: 2<br>P46: 11<br>P79: 8",
                  "<br>P41: 1",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P145: 1",
                  "",
                  "<br>P21: 1",
                  "",
                  "<br>P81: 1",
                  "<br>P82: 10",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "<br>P54: 12",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P158: 46<br>P157: 44<br>P153: 46<br>P163: 13",
                  "<br>P148: 4<br>P151: 2<br>P8: 3<br>P139: 3<br>P146: 6<br>P147: 4<br>P138: 3<br>P131: 3<br>P110: 2<br>P137: 2<br>P150: 2<br>P149: 2<br>P109: 3<br>P152: 2<br>P169: 1<br>P136: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P137: 1<br>P149: 2<br>P152: 2<br>P110: 1<br>P131: 1<br>P151: 2<br>P8: 2<br>P139: 2<br>P148: 2<br>P147: 2<br>P150: 2<br>P138: 1<br>P109: 1<br>P136: 1<br>P146: 2",
                  "",
                  "",
                  "<br>P24: 5<br>P50: 3<br>P30: 3<br>P19: 3<br>P10: 2",
                  "",
                  "",
                  "<br>P56: 11",
                  "<br>P36: 2<br>P123: 1<br>P121: 1<br>P120: 1<br>P94: 1<br>P122: 1<br>P35: 2<br>P140: 2<br>P144: 2<br>P141: 2<br>P111: 1<br>P143: 2<br>P34: 2<br>P142: 2",
                  "<br>P81: 1",
                  "<br>P46: 1<br>P116: 3<br>P79: 8",
                  "<br>P41: 1",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1",
                  "",
                  "<br>P21: 1",
                  "",
                  "<br>P96: 8<br>P81: 1",
                  "<br>P82: 10",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P158: 24<br>P157: 24<br>P153: 23<br>P163: 24",
                  "<br>P149: 4<br>P152: 4<br>P138: 3<br>P136: 2<br>P148: 4<br>P147: 6<br>P169: 1<br>P109: 3<br>P131: 2<br>P8: 2<br>P146: 6<br>P150: 4<br>P151: 4<br>P110: 2<br>P139: 2<br>P137: 3",
                ],
                [
                  "<br>P6: 18",
                  "<br>P146: 2<br>P109: 1<br>P149: 2<br>P150: 2<br>P151: 2<br>P148: 2<br>P147: 2<br>P137: 1<br>P8: 1<br>P138: 1<br>P136: 1<br>P139: 1<br>P110: 1<br>P152: 2<br>P131: 1",
                  "",
                  "",
                  "<br>P24: 2<br>P50: 1<br>P30: 6<br>P10: 4<br>P19: 7",
                  "",
                  "<br>P89: 16",
                  "<br>P56: 11",
                  "<br>P35: 2<br>P94: 2<br>P140: 1<br>P144: 2<br>P111: 1<br>P143: 2<br>P34: 3<br>P36: 2<br>P123: 2<br>P121: 2<br>P141: 2<br>P142: 2<br>P122: 2<br>P120: 2",
                  "<br>P81: 1",
                  "<br>P46: 1<br>P116: 3<br>P79: 8",
                  "<br>P41: 1",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P160: 1",
                  "",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P96: 8<br>P81: 1",
                  "<br>P82: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P157: 19<br>P153: 20<br>P158: 20<br>P163: 20",
                  "<br>P136: 3<br>P151: 4<br>P137: 3<br>P152: 6<br>P150: 4<br>P131: 3<br>P149: 4<br>P147: 4<br>P138: 1<br>P148: 4<br>P146: 4<br>P109: 1<br>P110: 3<br>P8: 1<br>P169: 1<br>P139: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P150: 4<br>P149: 2<br>P137: 1<br>P152: 2<br>P136: 1<br>P110: 1<br>P151: 2<br>P109: 1<br>P147: 2<br>P146: 2<br>P148: 2<br>P138: 1<br>P139: 1<br>P8: 1<br>P131: 1",
                  "",
                  "",
                  "<br>P50: 6<br>P30: 9<br>P10: 6<br>P24: 11<br>P19: 8",
                  "",
                  "<br>P89: 1",
                  "<br>P56: 1<br>P15: 5",
                  "<br>P35: 2<br>P140: 1<br>P143: 2<br>P144: 2<br>P34: 2<br>P123: 1<br>P120: 1<br>P94: 1<br>P141: 1<br>P36: 2<br>P121: 1<br>P142: 1<br>P111: 1<br>P122: 1",
                  "<br>P81: 1",
                  "<br>P46: 1<br>P116: 3<br>P79: 8",
                  "<br>P119: 15<br>P41: 1",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P160: 1",
                  "",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P81: 1<br>P96: 1",
                  "<br>P82: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P153: 16<br>P163: 14<br>P158: 14<br>P157: 14",
                  "<br>P150: 2<br>P147: 2<br>P139: 1<br>P8: 1<br>P137: 1<br>P109: 2<br>P151: 2<br>P148: 2<br>P110: 1<br>P149: 2<br>P131: 1<br>P152: 2<br>P136: 1<br>P146: 2<br>P138: 1<br>P169: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P109: 1<br>P150: 2<br>P151: 2<br>P137: 1<br>P149: 2<br>P131: 1<br>P136: 1<br>P139: 1<br>P138: 1<br>P147: 2<br>P110: 1<br>P146: 2<br>P152: 2<br>P8: 1<br>P148: 2",
                  "",
                  "",
                  "<br>P30: 9<br>P10: 6<br>P24: 11<br>P19: 8<br>P50: 9",
                  "<br>P65: 8<br>P99: 8<br>P100: 8<br>P98: 8<br>P91: 8",
                  "",
                  "<br>P15: 1",
                  "<br>P36: 2<br>P111: 1<br>P94: 3<br>P120: 2<br>P35: 1<br>P122: 1<br>P121: 1<br>P140: 1<br>P123: 1<br>P143: 1<br>P144: 1<br>P34: 3<br>P142: 1<br>P141: 2",
                  "<br>P81: 1",
                  "<br>P116: 3<br>P46: 1<br>P79: 8",
                  "<br>P41: 1<br>P118: 8",
                  "<br>P12: 1",
                  "<br>P68: 4",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1<br>P160: 1",
                  "<br>P76: 4",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P92: 4<br>P92: 4<br>P81: 1<br>P96: 1",
                  "<br>P82: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P158: 24<br>P157: 24<br>P153: 24<br>P163: 24",
                  "<br>P150: 6<br>P151: 4<br>P147: 6<br>P149: 6<br>P148: 6<br>P146: 6<br>P8: 2<br>P169: 1<br>P152: 4<br>P139: 2<br>P131: 2<br>P137: 2<br>P110: 2<br>P138: 2<br>P136: 3<br>P109: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P137: 2<br>P150: 4<br>P138: 2<br>P152: 4<br>P147: 4<br>P8: 1<br>P131: 2<br>P148: 4<br>P109: 2<br>P110: 2<br>P151: 4<br>P149: 4<br>P139: 2<br>P136: 2<br>P146: 4<br>P158: 2<br>P163: 2",
                  "",
                  "",
                  "<br>P30: 9<br>P10: 6<br>P24: 11<br>P19: 9",
                  "<br>P101: 4<br>P99: 2<br>P100: 2",
                  "",
                  "<br>P15: 1",
                  "<br>P35: 1<br>P140: 1<br>P36: 1<br>P142: 2<br>P121: 1<br>P94: 3<br>P143: 2<br>P141: 2<br>P122: 1<br>P111: 1<br>P123: 1<br>P144: 1<br>P120: 1<br>P34: 1",
                  "<br>P81: 1<br>P67: 3",
                  "<br>P116: 3<br>P46: 1<br>P79: 8",
                  "<br>P42: 4<br>P41: 1",
                  "<br>P12: 1",
                  "<br>P68: 4",
                  "",
                  "<br>P6: 6",
                  "<br>P154: 6",
                  "<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P92: 4<br>P92: 4<br>P81: 1",
                  "<br>P82: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P158: 26<br>P153: 26<br>P157: 26<br>P163: 46",
                  "<br>P136: 2<br>P138: 1<br>P151: 4<br>P131: 2<br>P109: 2<br>P152: 4<br>P150: 2<br>P139: 1<br>P149: 2<br>P146: 2<br>P110: 2<br>P148: 2<br>P147: 2<br>P137: 2<br>P8: 1<br>P169: 1",
                ],
                [
                  "<br>P6: 24",
                  "<br>P109: 2<br>P136: 2<br>P149: 2<br>P151: 2<br>P138: 2<br>P137: 2<br>P8: 1<br>P147: 2<br>P148: 2<br>P152: 2<br>P139: 1<br>P131: 1<br>P146: 2<br>P110: 1<br>P150: 2",
                  "",
                  "",
                  "<br>P30: 9<br>P24: 11<br>P10: 4",
                  "<br>P44: 5",
                  "",
                  "<br>P15: 1",
                  "<br>P35: 1<br>P34: 3<br>P111: 2<br>P94: 5<br>P123: 1<br>P142: 1<br>P144: 1<br>P140: 2<br>P141: 1<br>P122: 1<br>P143: 1<br>P120: 1<br>P36: 1<br>P121: 1",
                  "<br>P81: 1",
                  "<br>P116: 3<br>P79: 3",
                  "<br>P42: 4<br>P41: 1",
                  "<br>P12: 1",
                  "<br>P68: 1",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P160: 1",
                  "<br>P76: 20",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P92: 4<br>P92: 4<br>P81: 1<br>P96: 2",
                  "<br>P82: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P92: 1<br>P92: 1",
                  "<br>P41: 3",
                  "<br>P46: 2",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 10",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P157: 46<br>P158: 48<br>P163: 47<br>P153: 46",
                  "<br>P152: 4<br>P131: 2<br>P8: 3<br>P136: 3<br>P137: 3<br>P109: 2<br>P151: 2<br>P146: 2<br>P110: 2<br>P138: 3<br>P169: 1<br>P139: 3<br>P149: 4<br>P147: 4<br>P150: 4<br>P148: 4",
                ],
                [
                  "<br>P6: 24",
                  "<br>P150: 4<br>P146: 4<br>P151: 4<br>P149: 4<br>P109: 1<br>P148: 4<br>P152: 4<br>P139: 1<br>P147: 4<br>P163: 2<br>P137: 1<br>P110: 2<br>P8: 1<br>P136: 1<br>P138: 1<br>P131: 2<br>P158: 2",
                  "",
                  "",
                  "<br>P9: 10<br>P37: 3<br>P24: 11<br>P30: 9",
                  "<br>P101: 1",
                  "<br>P93: 8<br>P74: 4<br>P26: 2",
                  "<br>P15: 1<br>P56: 2",
                  "<br>P36: 2<br>P120: 1<br>P144: 2<br>P35: 1<br>P142: 1<br>P141: 1<br>P143: 2<br>P121: 1<br>P34: 1<br>P140: 2<br>P111: 1<br>P94: 3<br>P122: 1<br>P123: 1<br>P153: 2<br>P157: 2",
                  "<br>P81: 1<br>P67: 1",
                  "<br>P116: 4<br>P117: 2<br>P46: 2<br>P79: 1<br>P52: 2",
                  "<br>P41: 1<br>P103: 3<br>P42: 1",
                  "<br>P87: 10<br>P12: 1",
                  "<br>P68: 1",
                  "<br>P135: 3",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P160: 1",
                  "<br>P76: 20",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P92: 4<br>P92: 4<br>P81: 1<br>P96: 2",
                  "<br>P82: 4",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P92: 1<br>P92: 1<br>P79: 2",
                  "",
                  "<br>P46: 1<br>P135: 1",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 10",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P153: 45<br>P157: 44<br>P158: 44<br>P163: 43",
                  "<br>P150: 6<br>P147: 6<br>P110: 3<br>P152: 6<br>P136: 3<br>P148: 6<br>P137: 3<br>P151: 6<br>P149: 6<br>P146: 4<br>P138: 2<br>P109: 1<br>P131: 3<br>P169: 1<br>P139: 2<br>P8: 2<br>P163: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P139: 2<br>P151: 2<br>P148: 2<br>P146: 2<br>P8: 2<br>P138: 1<br>P109: 1<br>P147: 2<br>P137: 1<br>P152: 2<br>P149: 2<br>P150: 2<br>P110: 1<br>P131: 1<br>P136: 1",
                  "",
                  "",
                  "<br>P30: 1<br>P24: 9",
                  "<br>P91: 8<br>P99: 6<br>P100: 6<br>P65: 8<br>P98: 7<br>P83: 2<br>P2: 1",
                  "<br>P26: 2",
                  "<br>P15: 1<br>P56: 1",
                  "<br>P35: 1<br>P111: 1<br>P120: 2<br>P36: 2<br>P140: 1<br>P121: 2<br>P122: 1<br>P123: 1<br>P94: 3<br>P34: 2<br>P142: 3<br>P141: 3<br>P143: 2<br>P144: 2",
                  "<br>P81: 1<br>P67: 8",
                  "<br>P116: 4<br>P46: 1<br>P117: 4<br>P79: 1",
                  "<br>P103: 3<br>P119: 3<br>P41: 1<br>P42: 1",
                  "<br>P87: 8<br>P12: 1",
                  "<br>P68: 1",
                  "<br>P145: 2<br>P135: 3",
                  "<br>P6: 6",
                  "<br>P96: 1",
                  "<br>P41: 3<br>P15: 1",
                  "<br>P76: 16",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P81: 1<br>P130: 6<br>P48: 3<br>P92: 1<br>P92: 1",
                  "<br>P48: 11<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P92: 1<br>P92: 1<br>P79: 4",
                  "",
                  "<br>P46: 1",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 8",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P158: 30<br>P153: 28<br>P157: 28<br>P163: 6",
                  "<br>P146: 3<br>P110: 1<br>P147: 2<br>P148: 2<br>P152: 2<br>P137: 2<br>P150: 2<br>P151: 2<br>P138: 2<br>P139: 1<br>P131: 1<br>P8: 1<br>P149: 2<br>P109: 1<br>P136: 2<br>P169: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P8: 1<br>P148: 2<br>P151: 2<br>P147: 2<br>P149: 2<br>P152: 2<br>P139: 1<br>P146: 2<br>P110: 1<br>P150: 2<br>P109: 1<br>P136: 1<br>P131: 2<br>P137: 1<br>P138: 1",
                  "",
                  "",
                  "<br>P30: 1<br>P10: 1",
                  "<br>P83: 2<br>P3: 1<br>P99: 3<br>P98: 2<br>P65: 2<br>P91: 2<br>P100: 4",
                  "",
                  "<br>P15: 1<br>P56: 1",
                  "<br>P35: 1<br>P140: 1<br>P122: 2<br>P36: 2<br>P142: 1<br>P94: 5<br>P143: 1<br>P121: 2<br>P144: 1<br>P141: 1<br>P111: 2<br>P123: 2<br>P120: 2<br>P34: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P46: 1<br>P79: 1",
                  "<br>P41: 11<br>P118: 2",
                  "<br>P87: 8<br>P12: 1",
                  "<br>P68: 1",
                  "<br>P145: 2<br>P135: 3",
                  "<br>P6: 6",
                  "<br>P96: 1",
                  "<br>P41: 3<br>P15: 1",
                  "<br>P76: 16",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P48: 3<br>P130: 2<br>P92: 1<br>P92: 1",
                  "<br>P48: 11<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P92: 1<br>P92: 1<br>P79: 4",
                  "",
                  "<br>P46: 1",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 8",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P163: 22<br>P158: 24<br>P157: 24<br>P153: 24",
                  "<br>P150: 2<br>P149: 2<br>P138: 1<br>P152: 2<br>P131: 1<br>P151: 2<br>P147: 4<br>P148: 4<br>P137: 1<br>P146: 4<br>P109: 1<br>P110: 1<br>P136: 1<br>P169: 1<br>P139: 1<br>P8: 1<br>P163: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P139: 1<br>P147: 2<br>P151: 2<br>P150: 2<br>P109: 1<br>P8: 1<br>P110: 1<br>P152: 2<br>P148: 2<br>P146: 2<br>P149: 2<br>P138: 1<br>P137: 1<br>P136: 1<br>P131: 1",
                  "<br>P72: 8",
                  "",
                  "<br>P9: 8<br>P37: 2<br>P30: 1<br>P10: 1",
                  "<br>P65: 8<br>P44: 4<br>P100: 8<br>P91: 8<br>P99: 8<br>P98: 8<br>P83: 2<br>P3: 1",
                  "",
                  "<br>P15: 1",
                  "<br>P35: 1<br>P143: 1<br>P142: 1<br>P144: 1<br>P34: 1<br>P36: 1<br>P140: 1<br>P141: 2<br>P94: 2<br>P111: 1<br>P122: 1<br>P120: 1<br>P121: 1<br>P123: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P46: 1<br>P117: 4<br>P79: 1",
                  "<br>P41: 1",
                  "<br>P87: 8<br>P12: 1",
                  "<br>P68: 7",
                  "<br>P145: 1",
                  "<br>P6: 6",
                  "<br>P96: 2",
                  "<br>P41: 3<br>P15: 1",
                  "<br>P76: 16",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P130: 6<br>P92: 1<br>P48: 3<br>P92: 1",
                  "<br>P48: 11<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 4<br>P92: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 8",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P163: 20<br>P158: 18<br>P153: 18<br>P157: 18",
                  "<br>P151: 2<br>P131: 3<br>P110: 2<br>P8: 1<br>P109: 1<br>P147: 4<br>P138: 3<br>P146: 4<br>P150: 2<br>P148: 2<br>P136: 3<br>P137: 3<br>P149: 2<br>P152: 2<br>P139: 1<br>P169: 1",
                ],
                [
                  "<br>P6: 24",
                  "<br>P151: 2<br>P139: 1<br>P109: 1<br>P152: 2<br>P148: 2<br>P110: 1<br>P146: 2<br>P147: 2<br>P131: 1<br>P150: 2<br>P138: 2<br>P137: 1<br>P136: 1<br>P149: 2<br>P8: 1",
                  "<br>P72: 8",
                  "",
                  "<br>P30: 1<br>P10: 1",
                  "<br>P101: 1<br>P83: 2<br>P100: 2<br>P99: 1<br>P98: 1",
                  "<br>P26: 8",
                  "<br>P23: 12",
                  "<br>P123: 1<br>P94: 3<br>P143: 2<br>P144: 2<br>P141: 2<br>P120: 1<br>P35: 1<br>P122: 1<br>P142: 2<br>P34: 1<br>P121: 1<br>P111: 1<br>P140: 1<br>P36: 2",
                  "<br>P67: 8<br>P81: 1",
                  "<br>P116: 4<br>P79: 3<br>P46: 1",
                  "<br>P41: 1",
                  "<br>P87: 8<br>P12: 1",
                  "<br>P68: 3",
                  "<br>P145: 1",
                  "<br>P6: 6",
                  "<br>P96: 2",
                  "<br>P41: 3<br>P15: 1",
                  "<br>P76: 16",
                  "<br>P21: 8",
                  "<br>P21: 4",
                  "<br>P92: 1<br>P48: 3",
                  "<br>P48: 1<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 8",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P157: 30<br>P163: 19<br>P153: 30<br>P158: 31",
                  "<br>P139: 2<br>P146: 2<br>P137: 1<br>P147: 2<br>P138: 2<br>P109: 1<br>P148: 2<br>P149: 2<br>P8: 2<br>P151: 4<br>P152: 4<br>P136: 2<br>P110: 1<br>P150: 2<br>P131: 1<br>P169: 1<br>P163: 2",
                ],
                [
                  "<br>P6: 24",
                  "<br>P139: 2<br>P146: 2<br>P109: 1<br>P131: 1<br>P8: 2<br>P138: 2<br>P137: 1<br>P136: 1<br>P110: 1<br>P150: 2<br>P152: 2<br>P147: 2<br>P151: 2<br>P149: 2<br>P148: 2",
                  "<br>P72: 8",
                  "",
                  "<br>P30: 1<br>P10: 1",
                  "<br>P100: 2<br>P91: 2<br>P99: 2<br>P98: 2<br>P65: 2",
                  "<br>P26: 8",
                  "",
                  "<br>P142: 1<br>P120: 2<br>P94: 6<br>P121: 2<br>P144: 1<br>P111: 2<br>P35: 1<br>P141: 1<br>P122: 2<br>P123: 2<br>P36: 1<br>P140: 2<br>P143: 1<br>P34: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P79: 3<br>P46: 1",
                  "<br>P41: 1<br>P42: 3",
                  "<br>P87: 10<br>P12: 1",
                  "<br>P68: 3",
                  "<br>P145: 1",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P15: 1<br>P160: 1",
                  "<br>P76: 20",
                  "<br>P21: 3",
                  "<br>P21: 4",
                  "<br>P92: 1<br>P48: 3<br>P92: 2",
                  "<br>P48: 1<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 10",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P158: 56<br>P157: 52<br>P153: 52<br>P163: 24",
                  "<br>P150: 4<br>P149: 4<br>P148: 4<br>P152: 4<br>P146: 2<br>P139: 2<br>P151: 4<br>P131: 2<br>P110: 2<br>P138: 2<br>P169: 1<br>P137: 2<br>P109: 1<br>P8: 2<br>P163: 2<br>P136: 2<br>P147: 2",
                ],
                [
                  "<br>P6: 23",
                  "<br>P109: 1<br>P136: 2<br>P137: 2<br>P139: 2<br>P131: 2<br>P138: 2<br>P8: 1<br>P148: 4<br>P150: 4<br>P146: 2<br>P147: 4<br>P151: 4<br>P152: 4<br>P149: 4<br>P110: 2",
                  "<br>P72: 1",
                  "",
                  "<br>P37: 2<br>P9: 8",
                  "",
                  "",
                  "",
                  "<br>P35: 1<br>P140: 2<br>P142: 1<br>P143: 1<br>P111: 1<br>P34: 1<br>P144: 1<br>P94: 1<br>P141: 1<br>P121: 1<br>P120: 1<br>P122: 1<br>P123: 1<br>P36: 1",
                  "<br>P71.2: 12<br>P71.1: 12<br>P81: 1",
                  "<br>P116: 4<br>P52: 2<br>P117: 2<br>P79: 3",
                  "<br>P41: 1<br>P42: 5",
                  "<br>P87: 8<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "<br>P154: 2",
                  "<br>P41: 3<br>P15: 1<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "<br>P21: 4",
                  "<br>P92: 1<br>P130: 2<br>P92: 1<br>P48: 3",
                  "<br>P48: 12<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P21: 4",
                  "<br>P88: 8",
                  "<br>P21: 4",
                  "",
                  "<br>P21: 4",
                  "<br>P153: 28<br>P157: 28<br>P158: 26<br>P163: 28",
                  "<br>P146: 2<br>P148: 2<br>P8: 2<br>P110: 1<br>P152: 2<br>P139: 2<br>P147: 2<br>P138: 2<br>P149: 2<br>P151: 2<br>P150: 2<br>P137: 1<br>P131: 1<br>P109: 1<br>P136: 2<br>P169: 1<br>P163: 4",
                ],
                [
                  "<br>P6: 12",
                  "<br>P148: 2<br>P152: 2<br>P110: 1<br>P146: 2<br>P147: 2<br>P150: 2<br>P149: 2<br>P8: 1<br>P151: 2<br>P136: 1<br>P139: 1<br>P131: 1<br>P109: 1<br>P137: 1<br>P138: 1",
                  "",
                  "",
                  "<br>P19: 2<br>P37: 1",
                  "<br>P99: 8<br>P100: 8<br>P98: 8<br>P91: 8<br>P65: 8",
                  "",
                  "",
                  "<br>P35: 1<br>P140: 1<br>P36: 1<br>P121: 1<br>P94: 3<br>P123: 1<br>P122: 1<br>P120: 1<br>P143: 1<br>P142: 1<br>P144: 1<br>P111: 1<br>P141: 1<br>P34: 1",
                  "<br>P67: 8<br>P81: 1",
                  "<br>P116: 4<br>P79: 3<br>P117: 4<br>P52: 4",
                  "<br>P41: 1<br>P42: 5",
                  "<br>P87: 8",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P15: 1<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "",
                  "<br>P130: 6<br>P92: 1",
                  "<br>P48: 12<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P88: 8",
                  "",
                  "",
                  "",
                  "<br>P153: 20<br>P163: 20<br>P158: 21<br>P157: 18",
                  "<br>P147: 2<br>P136: 2<br>P149: 2<br>P138: 1<br>P152: 2<br>P150: 2<br>P148: 2<br>P146: 2<br>P109: 1<br>P131: 1<br>P110: 1<br>P137: 1<br>P8: 1<br>P151: 2<br>P169: 1<br>P139: 1<br>P163: 4",
                ],
                [
                  "<br>P6: 18",
                  "<br>P151: 2<br>P152: 1<br>P147: 2<br>P149: 2<br>P148: 2<br>P150: 2<br>P146: 2<br>P109: 1<br>P139: 1<br>P8: 1<br>P131: 1<br>P137: 2<br>P138: 2<br>P110: 1<br>P136: 1",
                  "",
                  "",
                  "<br>P19: 2",
                  "",
                  "",
                  "",
                  "<br>P35: 1<br>P143: 1<br>P141: 1<br>P144: 1<br>P142: 1<br>P34: 1<br>P94: 6<br>P36: 2<br>P121: 2<br>P120: 2<br>P123: 2<br>P140: 1<br>P122: 2<br>P111: 1",
                  "<br>P81: 1",
                  "<br>P116: 4<br>P79: 3",
                  "<br>P41: 1",
                  "<br>P87: 12",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 4<br>P92: 1",
                  "<br>P48: 12<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P88: 8",
                  "",
                  "",
                  "",
                  "<br>P157: 28<br>P158: 28<br>P163: 28<br>P153: 28",
                  "<br>P152: 4<br>P109: 3<br>P147: 4<br>P149: 2<br>P151: 2<br>P131: 2<br>P8: 2<br>P148: 2<br>P146: 4<br>P139: 2<br>P137: 1<br>P110: 2<br>P136: 2<br>P138: 1<br>P150: 2<br>P169: 1<br>P163: 2",
                ],
                [
                  "<br>P6: 18",
                  "<br>P148: 4<br>P109: 2<br>P152: 4<br>P131: 1<br>P146: 4<br>P151: 4<br>P8: 1<br>P150: 4<br>P139: 1<br>P147: 4<br>P149: 4<br>P110: 1<br>P136: 1<br>P138: 1<br>P137: 1",
                  "",
                  "",
                  "<br>P30: 9<br>P10: 1<br>P24: 15<br>P50: 9<br>P19: 9",
                  "",
                  "<br>P74: 5",
                  "",
                  "<br>P35: 1<br>P141: 1<br>P34: 1<br>P123: 1<br>P120: 1<br>P94: 2<br>P121: 1<br>P36: 2<br>P144: 1<br>P122: 1<br>P140: 1<br>P143: 2<br>P142: 2<br>P111: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P55: 9<br>P79: 3<br>P117: 2",
                  "<br>P41: 1<br>P119: 3",
                  "",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1<br>P92: 1",
                  "<br>P53: 8<br>P82: 2<br>P48: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P157: 26<br>P163: 28<br>P158: 26<br>P153: 26",
                  "<br>P149: 2<br>P146: 2<br>P8: 1<br>P139: 1<br>P148: 2<br>P136: 1<br>P147: 2<br>P150: 1<br>P109: 1<br>P138: 1<br>P137: 1<br>P169: 1<br>P151: 2<br>P152: 2<br>P110: 1<br>P131: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P148: 2<br>P152: 2<br>P146: 2<br>P147: 2<br>P150: 2<br>P149: 2<br>P109: 1<br>P151: 2<br>P110: 1<br>P136: 1<br>P131: 1<br>P137: 1<br>P138: 1<br>P139: 1<br>P8: 2",
                  "<br>P108: 12<br>P73: 12",
                  "",
                  "<br>P30: 1<br>P10: 1<br>P24: 15<br>P19: 9<br>P50: 3",
                  "",
                  "<br>P74: 1",
                  "",
                  "<br>P94: 2<br>P141: 3<br>P142: 3<br>P143: 1<br>P140: 2<br>P144: 1<br>P111: 2<br>P35: 1<br>P123: 2<br>P34: 2<br>P120: 2<br>P121: 2<br>P122: 2<br>P36: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P117: 2<br>P79: 3<br>P55: 1",
                  "<br>P41: 1",
                  "",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1<br>P92: 1",
                  "<br>P48: 1",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P158: 18<br>P153: 16<br>P157: 18<br>P163: 16",
                  "<br>P131: 2<br>P152: 4<br>P150: 4<br>P137: 2<br>P146: 4<br>P110: 2<br>P147: 4<br>P151: 4<br>P148: 4<br>P139: 2<br>P8: 2<br>P136: 2<br>P149: 4<br>P109: 1<br>P169: 1<br>P138: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P146: 2<br>P148: 4<br>P147: 4<br>P150: 4<br>P152: 2<br>P109: 1<br>P149: 4<br>P151: 4<br>P110: 1<br>P137: 1<br>P136: 1<br>P131: 1<br>P8: 1<br>P138: 1<br>P139: 1",
                  "",
                  "",
                  "<br>P30: 1<br>P10: 1<br>P24: 15<br>P19: 1<br>P50: 2",
                  "<br>P2: 4<br>P83: 8<br>P3: 4",
                  "<br>P26: 2",
                  "<br>P56: 2",
                  "<br>P140: 2<br>P35: 1<br>P143: 1<br>P142: 1<br>P94: 3<br>P34: 2<br>P111: 1<br>P120: 1<br>P141: 1<br>P121: 1<br>P36: 1<br>P122: 1<br>P123: 1<br>P144: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P79: 3<br>P117: 2",
                  "<br>P41: 1<br>P42: 4",
                  "<br>P12: 5",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 3<br>P160: 1",
                  "<br>P76: 16",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1<br>P96: 2",
                  "<br>P48: 1<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P79: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P153: 16<br>P157: 18<br>P158: 16<br>P163: 16",
                  "<br>P147: 2<br>P146: 4<br>P148: 2<br>P138: 1<br>P136: 1<br>P109: 2<br>P8: 2<br>P139: 2<br>P137: 1<br>P150: 4<br>P131: 3<br>P151: 4<br>P110: 2<br>P152: 4<br>P149: 4<br>P169: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P146: 4<br>P151: 2<br>P109: 2<br>P148: 4<br>P147: 4<br>P149: 2<br>P139: 2<br>P138: 2<br>P8: 2<br>P150: 2<br>P152: 2<br>P110: 1<br>P131: 1<br>P136: 1<br>P137: 1",
                  "",
                  "",
                  "<br>P30: 1<br>P10: 1<br>P24: 15<br>P19: 2<br>P50: 4",
                  "<br>P83: 8<br>P3: 4<br>P2: 2",
                  "<br>P26: 2",
                  "<br>P28: 8<br>P56: 1",
                  "<br>P35: 1<br>P140: 1<br>P122: 1<br>P111: 1<br>P142: 1<br>P121: 1<br>P141: 1<br>P36: 1<br>P120: 1<br>P94: 3<br>P123: 1<br>P143: 1<br>P34: 1<br>P144: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P117: 2",
                  "<br>P41: 1<br>P42: 4",
                  "<br>P12: 5",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1",
                  "<br>P76: 20",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1<br>P96: 2",
                  "<br>P48: 1<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P158: 24<br>P153: 24<br>P163: 26<br>P157: 24",
                  "<br>P138: 1<br>P8: 1<br>P109: 2<br>P148: 2<br>P147: 2<br>P131: 1<br>P139: 1<br>P110: 1<br>P169: 1<br>P149: 2<br>P146: 2<br>P151: 2<br>P150: 2<br>P136: 1<br>P152: 2<br>P137: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P8: 1<br>P138: 1<br>P109: 1<br>P146: 2<br>P147: 2<br>P139: 1<br>P149: 2<br>P150: 2<br>P152: 4<br>P137: 2<br>P136: 2<br>P110: 2<br>P131: 2<br>P151: 2<br>P148: 2",
                  "",
                  "",
                  "<br>P30: 1<br>P24: 15<br>P10: 3<br>P19: 2<br>P50: 2",
                  "<br>P83: 8<br>P98: 1<br>P99: 1<br>P11: 2<br>P3: 2",
                  "",
                  "<br>P56: 1",
                  "<br>P35: 2<br>P140: 1<br>P36: 1<br>P94: 7<br>P111: 3<br>P120: 2<br>P121: 2<br>P122: 2<br>P142: 1<br>P143: 1<br>P144: 1<br>P141: 2<br>P123: 2<br>P34: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P117: 4",
                  "<br>P41: 1",
                  "<br>P12: 5",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1",
                  "<br>P76: 8",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1",
                  "<br>P48: 1<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P157: 22<br>P163: 22<br>P158: 22<br>P153: 21",
                  "<br>P110: 3<br>P150: 2<br>P152: 6<br>P137: 3<br>P146: 2<br>P151: 6<br>P8: 1<br>P148: 2<br>P136: 3<br>P131: 3<br>P139: 1<br>P147: 2<br>P149: 2<br>P138: 2<br>P109: 2<br>P169: 1",
                ],
                [
                  "<br>P6: 18",
                  "<br>P137: 1<br>P8: 1<br>P138: 1<br>P136: 1<br>P139: 1<br>P150: 2<br>P147: 3<br>P149: 2<br>P146: 4<br>P148: 2<br>P131: 1<br>P152: 2<br>P110: 1<br>P109: 2<br>P151: 2",
                  "",
                  "",
                  "<br>P24: 2<br>P30: 2<br>P10: 2<br>P19: 4<br>P50: 2",
                  "<br>P83: 8<br>P2: 1<br>P3: 1",
                  "",
                  "",
                  "<br>P142: 1<br>P143: 1<br>P120: 2<br>P94: 5<br>P36: 3<br>P121: 2<br>P123: 2<br>P141: 1<br>P111: 1<br>P122: 1<br>P140: 1<br>P35: 2<br>P144: 1<br>P34: 1",
                  "<br>P81: 1",
                  "<br>P116: 2<br>P117: 1",
                  "<br>P41: 1",
                  "<br>P12: 1",
                  "",
                  "",
                  "<br>P6: 6",
                  "",
                  "<br>P41: 1",
                  "",
                  "<br>P21: 3",
                  "",
                  "<br>P81: 1",
                  "<br>P82: 2",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P41: 3",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "<br>P163: 20<br>P158: 18<br>P153: 19<br>P157: 18",
                  "<br>P150: 2<br>P149: 2<br>P109: 3<br>P152: 2<br>P147: 4<br>P8: 3<br>P110: 1<br>P146: 6<br>P148: 2<br>P139: 3<br>P136: 2<br>P137: 2<br>P151: 2<br>P138: 2<br>P169: 1<br>P131: 1",
                ],
              ],
              type: "heatmap",
              x: [
                "CALPR00000",
                "CALPR00016",
                "CALPR00017",
                "CALPR00018",
                "CALPR00030",
                "CALPR00058",
                "CALPR00059",
                "CALPR00062",
                "CALPR00063",
                "CALPR00064",
                "CALPR00078",
                "CALPR00079",
                "CALPR00080",
                "CALPR00081",
                "CALPR00083",
                "CALPR00093",
                "CALPR00094",
                "CALPR00095",
                "CALPR00096",
                "CALPR00097",
                "CALPR00098",
                "CALPR00099",
                "CALPR00100",
                "CALPR00103",
                "CALPR00103",
                "CALPR00103",
                "CALPR00103",
                "CALPR00103",
                "CALPR00105",
                "CALPR00106",
                "CALPR00107",
                "CALPR00108",
                "CALPR00109",
                "CALPR00112",
                "CALPR00113",
                "CALPR00134",
                "CALPR00136",
                "CALPR00137",
                "CALPR00139",
                "CALPR00144",
                "CALPR00145",
              ],
              z: [
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 1.0, 1.0, 0.0, 14.0, 1.0, 4.0, 1.0,
                  1.0, 1.0, 0.0, 1.0, 0.0, 3.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 4.0, 1.0, 1.0, 0.0, 14.0, 1.0, 4.0, 2.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 3.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 2.0, 0.0, 5.0, 2.0, 1.0, 0.0, 14.0, 1.0, 4.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 2.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 0.0, 0.0, 1.0, 14.0, 1.0, 3.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 0.0, 1.0, 0.0, 2.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 0.0, 1.0, 1.0, 14.0, 1.0, 3.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 2.0, 0.0, 1.0, 1.0, 2.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 0.0, 1.0, 2.0, 14.0, 1.0, 3.0, 2.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 2.0, 0.0, 1.0, 1.0, 2.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 5.0, 0.0, 1.0, 14.0, 1.0, 3.0, 2.0,
                  1.0, 1.0, 0.0, 1.0, 0.0, 2.0, 1.0, 1.0, 1.0, 4.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 17.0, 0.0, 0.0, 4.0, 3.0, 0.0, 1.0, 14.0, 2.0, 3.0, 2.0,
                  1.0, 1.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 3.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 3.0, 1.0, 0.0, 1.0, 14.0, 1.0, 2.0, 2.0,
                  1.0, 1.0, 0.0, 1.0, 0.0, 1.0, 1.0, 1.0, 1.0, 4.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 2.0, 1.0, 1.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 17.0, 0.0, 0.0, 4.0, 1.0, 3.0, 2.0, 16.0, 2.0, 5.0, 3.0,
                  2.0, 1.0, 1.0, 1.0, 0.0, 2.0, 1.0, 1.0, 1.0, 4.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 3.0, 0.0, 2.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 2.0, 7.0, 1.0, 2.0, 14.0, 2.0, 4.0, 4.0,
                  2.0, 1.0, 2.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0, 5.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 3.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 2.0, 7.0, 0.0, 2.0, 14.0, 1.0, 3.0, 2.0,
                  2.0, 1.0, 2.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0, 4.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 3.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 1.0, 0.0, 4.0, 8.0, 0.0, 1.0, 14.0, 1.0, 4.0, 1.0,
                  2.0, 1.0, 1.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0, 4.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 2.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 1.0, 0.0, 2.0, 5.0, 1.0, 1.0, 14.0, 2.0, 3.0, 1.0,
                  2.0, 1.0, 1.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0, 2.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 1.0, 0.0, 2.0, 5.0, 1.0, 0.0, 14.0, 1.0, 3.0, 2.0,
                  2.0, 1.0, 1.0, 1.0, 0.0, 3.0, 1.0, 1.0, 1.0, 3.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 1.0, 0.0, 2.0, 0.0, 0.0, 0.0, 14.0, 3.0, 4.0, 2.0,
                  2.0, 0.0, 0.0, 1.0, 1.0, 3.0, 1.0, 1.0, 1.0, 4.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
                  1.0, 0.0, 1.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 2.0, 5.0, 0.0, 0.0, 14.0, 2.0, 4.0, 2.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 3.0, 1.0, 1.0, 0.0, 2.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
                  0.0, 0.0, 0.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 14.0, 1.0, 2.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 2.0, 1.0, 1.0, 0.0, 2.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
                  0.0, 0.0, 0.0, 4.0, 17.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 0.0, 1.0, 0.0, 14.0, 1.0, 4.0, 2.0,
                  0.0, 0.0, 0.0, 1.0, 0.0, 2.0, 1.0, 1.0, 0.0, 2.0, 3.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 2.0, 0.0, 5.0, 0.0, 1.0, 0.0, 14.0, 1.0, 4.0, 1.0,
                  0.0, 0.0, 0.0, 1.0, 0.0, 2.0, 1.0, 1.0, 0.0, 2.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 3.0, 1.0, 1.0, 14.0, 1.0, 3.0, 2.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 2.0, 1.0, 1.0, 0.0, 2.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 3.0, 1.0, 2.0, 14.0, 1.0, 2.0, 2.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 2.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 5.0, 0.0, 1.0, 14.0, 1.0, 2.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 1.0, 2.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
                [
                  1.0, 15.0, 0.0, 0.0, 5.0, 3.0, 0.0, 0.0, 14.0, 1.0, 2.0, 1.0,
                  1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                  0.0, 0.0, 0.0, 4.0, 16.0,
                ],
              ],
            },
          ]}
          annotations={[
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P147",
              x: "CALPR00016",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P3",
              x: "CALPR00058",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P62",
              x: "CALPR00059",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P54",
              x: "CALPR00081",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00095",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00145",
              xref: "x",
              y: 0,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P152",
              x: "CALPR00016",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P3",
              x: "CALPR00058",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P94",
              x: "CALPR00063",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00078",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00095",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P163",
              x: "CALPR00144",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 1,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P148",
              x: "CALPR00016",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P78",
              x: "CALPR00017",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P100",
              x: "CALPR00058",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00095",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P54",
              x: "CALPR00107",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P148",
              x: "CALPR00145",
              xref: "x",
              y: 2,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P137",
              x: "CALPR00016",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P56",
              x: "CALPR00062",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P149",
              x: "CALPR00145",
              xref: "x",
              y: 3,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00016",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P89",
              x: "CALPR00059",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P56",
              x: "CALPR00062",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P136",
              x: "CALPR00145",
              xref: "x",
              y: 4,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00016",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P50",
              x: "CALPR00030",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P89",
              x: "CALPR00059",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00078",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P119",
              x: "CALPR00079",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P96",
              x: "CALPR00099",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P153",
              x: "CALPR00144",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 5,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P109",
              x: "CALPR00016",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P65",
              x: "CALPR00058",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00078",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 6,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P137",
              x: "CALPR00016",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P101",
              x: "CALPR00058",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00078",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P42",
              x: "CALPR00079",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P154",
              x: "CALPR00094",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P136",
              x: "CALPR00145",
              xref: "x",
              y: 7,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P109",
              x: "CALPR00016",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P44",
              x: "CALPR00058",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00078",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P42",
              x: "CALPR00079",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00105",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00107",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P152",
              x: "CALPR00145",
              xref: "x",
              y: 8,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00016",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P9",
              x: "CALPR00030",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P101",
              x: "CALPR00058",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P93",
              x: "CALPR00059",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P87",
              x: "CALPR00080",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P135",
              x: "CALPR00083",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00105",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00107",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P153",
              x: "CALPR00144",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 9,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P139",
              x: "CALPR00016",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P91",
              x: "CALPR00058",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00083",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P96",
              x: "CALPR00094",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00095",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00105",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00107",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00145",
              xref: "x",
              y: 10,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P8",
              x: "CALPR00016",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P10",
              x: "CALPR00030",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P83",
              x: "CALPR00058",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00083",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P96",
              x: "CALPR00094",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00105",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00107",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P163",
              x: "CALPR00144",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 11,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P139",
              x: "CALPR00016",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P72",
              x: "CALPR00017",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P9",
              x: "CALPR00030",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P65",
              x: "CALPR00058",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P15",
              x: "CALPR00062",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00083",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P96",
              x: "CALPR00094",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P130",
              x: "CALPR00099",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P163",
              x: "CALPR00144",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P151",
              x: "CALPR00145",
              xref: "x",
              y: 12,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P151",
              x: "CALPR00016",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P72",
              x: "CALPR00017",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P10",
              x: "CALPR00030",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P83",
              x: "CALPR00058",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P23",
              x: "CALPR00062",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00083",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P96",
              x: "CALPR00094",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P139",
              x: "CALPR00145",
              xref: "x",
              y: 13,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P139",
              x: "CALPR00016",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P72",
              x: "CALPR00017",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P10",
              x: "CALPR00030",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P100",
              x: "CALPR00058",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P46",
              x: "CALPR00078",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P87",
              x: "CALPR00080",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P68",
              x: "CALPR00081",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P145",
              x: "CALPR00083",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 14,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P109",
              x: "CALPR00016",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P72",
              x: "CALPR00017",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P37",
              x: "CALPR00030",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P154",
              x: "CALPR00094",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00098",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P92",
              x: "CALPR00099",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00113",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00136",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00139",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P153",
              x: "CALPR00144",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00145",
              xref: "x",
              y: 15,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P148",
              x: "CALPR00016",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P19",
              x: "CALPR00030",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P99",
              x: "CALPR00058",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P87",
              x: "CALPR00080",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P130",
              x: "CALPR00099",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P153",
              x: "CALPR00144",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P147",
              x: "CALPR00145",
              xref: "x",
              y: 16,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P151",
              x: "CALPR00016",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P19",
              x: "CALPR00030",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P87",
              x: "CALPR00080",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P88",
              x: "CALPR00134",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P152",
              x: "CALPR00145",
              xref: "x",
              y: 17,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P148",
              x: "CALPR00016",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P74",
              x: "CALPR00059",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00080",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P53",
              x: "CALPR00100",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P149",
              x: "CALPR00145",
              xref: "x",
              y: 18,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P148",
              x: "CALPR00016",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P108",
              x: "CALPR00017",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00058",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P74",
              x: "CALPR00059",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P140",
              x: "CALPR00063",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00080",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P48",
              x: "CALPR00100",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P131",
              x: "CALPR00145",
              xref: "x",
              y: 19,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00016",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P2",
              x: "CALPR00058",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P56",
              x: "CALPR00062",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P160",
              x: "CALPR00095",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P79",
              x: "CALPR00105",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00106",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P153",
              x: "CALPR00144",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P147",
              x: "CALPR00145",
              xref: "x",
              y: 20,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P146",
              x: "CALPR00016",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P30",
              x: "CALPR00030",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P83",
              x: "CALPR00058",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P26",
              x: "CALPR00059",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P28",
              x: "CALPR00062",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P158",
              x: "CALPR00144",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P138",
              x: "CALPR00145",
              xref: "x",
              y: 21,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P8",
              x: "CALPR00016",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P83",
              x: "CALPR00058",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P56",
              x: "CALPR00062",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P76",
              x: "CALPR00096",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P157",
              x: "CALPR00144",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P110",
              x: "CALPR00145",
              xref: "x",
              y: 22,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00000",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P137",
              x: "CALPR00016",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00017",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00018",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P24",
              x: "CALPR00030",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P83",
              x: "CALPR00058",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00059",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00062",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P35",
              x: "CALPR00063",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00064",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P116",
              x: "CALPR00078",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00079",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P12",
              x: "CALPR00080",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00081",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00083",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P6",
              x: "CALPR00093",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00094",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00095",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00096",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P21",
              x: "CALPR00097",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00098",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P81",
              x: "CALPR00099",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P82",
              x: "CALPR00100",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00103",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00105",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P41",
              x: "CALPR00106",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00107",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00108",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00109",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00112",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00113",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00134",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00136",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00137",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "",
              x: "CALPR00139",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#000000" },
              showarrow: false,
              text: "P163",
              x: "CALPR00144",
              xref: "x",
              y: 23,
              yref: "y",
            },
            {
              font: { color: "#FFFFFF" },
              showarrow: false,
              text: "P150",
              x: "CALPR00145",
              xref: "x",
              y: 23,
              yref: "y",
            },
          ]}
        />
      </div>
    </>
  );
};
