

export const Routes = {
    // pages
    Presentation: { path: "/" },
    DashboardExecutive: { path: "/dashboard/overview" },
    DashboardDetailed: { path: "/dashboard/detailed" },
    DashboardBacklog: { path: "/dashboard/backlog" },
    DashboardUtilisation: { path: "/dashboard/utilisation" },
    Ideate: { path: "/ideate" },
    Kanban: { path: "/kanban" },
    Messages: { path: "/messages" },
    SingleMessage: { path: "/message" },
    Users: { path: "/users" },
    Transactions: { path: "/transactions" },
    Tasks: { path: "/tasks" },
    Settings: { path: "/settings" },
    Profile: { path: "/settings/profile" },
    SettingsSolutions: { path: "/settings/solutions" },
    SettingsPlatforms: { path: "/settings/platforms" },
    SettingsQuotes: { path: "/settings/quotes" },
    Calendar: { path: "/calendar" },
    Map: { path: "/map" },
    Datatables: { path: "/tables/datatables" },
    TablesTransactions: { path: "/tables/transactions" },
    Ideas: { path: "/tables/ideas" },
    Solutions: { path: "/tables/solutions" },
    Queues: { path: "/tables/queues" },
    BusinessUnits: { path: "/tables/busines-sunits" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Pricing: { path: "/pricing" },
    ComingSoon: { path: "/examples/coming-soon" },
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // plugins
    PluginCharts: { path: "/plugins/charts" },
    PluginCalendar: { path: "/plugins/calendar" },
    PluginDatatable: { path: "/plugins/datatable" },
    PluginMap: { path: "/plugins/map" },
    PluginDropzone: { path: "/plugins/dropzone" },
    PluginSweetAlert: { path: "/plugins/sweet-alert" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};