import React from 'react'

const SingleTile = (props) => {
  const {
    dollarSign,
    color2,
    tilevalue,
    tiletext,
  } = props;

  return (
    <>
    <div class="row rounded mb-3 mx-0" style={{backgroundColor: '#f5f5f5'}}>
        <div class="col-sm-12 ps-1 pe-0">
          <div class="card bg-transparent" style={{ border: '0' }}>
            <div class="card-body text-center px-0 py-3 pt-4">
              <h3 class="card-title mb-1" style={{ color: `${color2}` }}>
                {dollarSign}
                {tilevalue}
              </h3>
              <p class="card-text mb-0" style={{ fontSize: "0.75rem" }}>{tiletext}</p>
            </div>
          </div>
        </div>
      </div>
    </>
    )
}

export default SingleTile