
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

export default [
    {
        "id": uuidv4(),
        "name": "Roy Fendley",
        "position": "System Architect",
        "office": "Edinburgh",
        "age": 60,
        "salary": 320800,
        "startDate": moment().subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Bonnie Green",
        "position": "Accountant",
        "office": "Tokyo",
        "age": 53,
        "salary": 170750,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Scott Anderson",
        "position": "Junior Technical Author",
        "office": "San Francisco",
        "age": 24,
        "salary": 86000,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Ronnie Buchanan",
        "position": "Senior Javascript Developer",
        "office": "Edinburgh",
        "age": 45,
        "salary": 533560,
        "startDate": moment().subtract(3, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Jane Rinehart",
        "position": "Accountant",
        "office": "Tokyo",
        "age": 33,
        "salary": 162700,
        "startDate": moment().subtract(4, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "William Ginther",
        "position": "Integration Specialist",
        "office": "New York",
        "age": 29,
        "salary": 372000,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "George Driskell",
        "position": "Sales Assistant",
        "office": "San Francisco",
        "age": 52,
        "salary": 137500,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Ronnie Buchanan",
        "position": "Integration Specialist",
        "office": "Tokyo",
        "age": 48,
        "salary": 327900,
        "startDate": moment().subtract(5, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Jane Rinehart",
        "position": "Javascript Developer",
        "office": "San Francisco",
        "age": 39,
        "salary": 205500,
        "startDate": moment().subtract(6, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Sonya Frost",
        "position": "Software Engineer",
        "office": "Edinburgh",
        "age": 23,
        "salary": 103400,
        "startDate": moment().subtract(6, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Jena Gaines",
        "position": "Office Manager",
        "office": "London",
        "age": 30,
        "salary": 90560,
        "startDate": moment().subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Quinn Flynn",
        "position": "Support Lead",
        "office": "Edinburgh",
        "age": 22,
        "salary": 342000,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Charde Marshall",
        "position": "Regional Director",
        "office": "San Francisco",
        "age": 36,
        "salary": 470600,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Haley Kennedy",
        "position": "Senior Marketing Designer",
        "office": "London",
        "age": 43,
        "salary": 313300,
        "startDate": moment().subtract(3, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Tatyana Fitzpatrick",
        "position": "Regional Director",
        "office": "London",
        "age": 19,
        "salary": 322000,
        "startDate": moment().subtract(4, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Michael Silva",
        "position": "Marketing Designer",
        "office": "London",
        "age": 29,
        "salary": 198000,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Paul Byrd",
        "position": "Chief Financial Officer (CFO)",
        "office": "New York",
        "age": 64,
        "salary": 725000,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Gloria Little",
        "position": "Systems Administrator",
        "office": "New York",
        "age": 59,
        "salary": 237500,
        "startDate": moment().subtract(5, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Bradley Greer",
        "position": "Software Engineer",
        "office": "San Francisco",
        "age": 41,
        "salary": 305900,
        "startDate": moment().subtract(6, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Dai Rios",
        "position": "Personnel Lead",
        "office": "Edinburgh",
        "age": 35,
        "salary": 223100,
        "startDate": moment().subtract(6, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Jenette Caldwell",
        "position": "Development Lead",
        "office": "New York",
        "age": 30,
        "salary": 345060,
        "startDate": moment().subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Yuri Berry",
        "position": "Chief Marketing Officer (CMO)",
        "office": "New York",
        "age": 40,
        "salary": 678000,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Caesar Vance",
        "position": "Pre-Sales Support",
        "office": "New York",
        "age": 21,
        "salary": 105600,
        "startDate": moment().subtract(2, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Doris Wilder",
        "position": "Sales Assistant",
        "office": "Sidney",
        "age": 23,
        "salary": 83300,
        "startDate": moment().subtract(3, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Angelica Ramos",
        "position": "Chief Executive Officer (CEO)",
        "office": "London",
        "age": 47,
        "salary": 1220000,
        "startDate": moment().subtract(4, "days").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Gavin Joyce",
        "position": "Developer",
        "office": "Edinburgh",
        "age": 42,
        "salary": 98200,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
    {
        "id": uuidv4(),
        "name": "Brenden Wagne",
        "position": "Chief Operating Officer (COO)",
        "office": "San Francisco",
        "age": 45,
        "salary": 890700,
        "startDate": moment().subtract(5, "months").subtract(1, "year").format("YYYY/MM/DD"),
    },
];