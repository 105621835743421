import React from "react";
import { v4 as uuidv4 } from "uuid";
import { GlobeIcon } from "@heroicons/react/solid";

import USAFlag from 'assets/img/flags/united-states-of-america.svg';
import CanadaFlag from 'assets/img/flags/canada.svg';
import GermanyFlag from 'assets/img/flags/germany.svg';
import FranceFlag from 'assets/img/flags/france.svg';
import JapanFlag from 'assets/img/flags/japan.svg';
import ItalyFlag from 'assets/img/flags/italy.svg';

import { GoogleIcon, TwitterIcon, YahooIcon, YoutubeIcon } from "components/BrandIcons";

const pageVisits = [
    { id: uuidv4(), views: 4.525, returnValue: 255, bounceRate: 42.55, pageName: "/demo/admin/index.html" },
    { id: uuidv4(), views: 2.987, returnValue: 139, bounceRate: -43.52, pageName: "/demo/admin/forms.html" },
    { id: uuidv4(), views: 2.844, returnValue: 124, bounceRate: -32.35, pageName: "/demo/admin/util.html" },
    { id: uuidv4(), views: 1.220, returnValue: 55, bounceRate: 15.78, pageName: "/demo/admin/validation.html" },
    { id: uuidv4(), views: 505, returnValue: 3, bounceRate: -75.12, pageName: "/demo/admin/modals.html" }
];

const pageTraffic = [
    { id: uuidv4(), source: "Lorem ipsum", sourceType: "dolor sit amet", trafficShare: 51, change: 2.45, sourceIcon: <GlobeIcon className="icon icon-xxs text-gray-500 me-2" /> },
    { id: uuidv4(), source: "Lorem ipsum", sourceType: "dolor sit amet", trafficShare: 18, change: 17.67, sourceIcon: <GlobeIcon className="icon icon-xxs text-gray-500 me-2" /> },
    { id: uuidv4(), source: "Lorem ipsum", sourceType: "dolor sit amet", category: "dolor sit amet", rank: 2, trafficShare: 27, sourceIcon: <GlobeIcon className="icon icon-xxs text-gray-500 me-2" /> },
    { id: uuidv4(), source: "Lorem ipsum", sourceType: "dolor sit amet", category: "dolor sit amet", rank: 11, trafficShare: 8, change: -9.30, sourceIcon: <GlobeIcon className="icon icon-xxs text-gray-500 me-2" /> },
    { id: uuidv4(), source: "Lorem ipsum", sourceType: "dolor sit amet", category: "dolor sit amet", rank: 4, trafficShare: 4, sourceIcon: <GlobeIcon className="icon icon-xxs text-gray-500 me-2" /> }
];

const pageRanking = [
    { id: uuidv4(), country: "United States", countryImage: USAFlag, overallRank: 76, overallRankChange: -5, travelRank: 3, widgetsRank: 32, widgetsRankChange: 3 },
    { id: uuidv4(), country: "Canada", countryImage: CanadaFlag, overallRank: 106, overallRankChange: 17, travelRank: 4, widgetsRank: 30, widgetsRankChange: 3 },
    { id: uuidv4(), country: "France", countryImage: FranceFlag, overallRank: 112, overallRankChange: 10, travelRank: 5, widgetsRank: 34, widgetsRankChange: 7 },
    { id: uuidv4(), country: "Japan", countryImage: JapanFlag, overallRank: 115, overallRankChange: 3, travelRank: 7, travelRankChange: 1, widgetsRank: 39, widgetsRankChange: -2 },
    { id: uuidv4(), country: "Germany", countryImage: GermanyFlag, overallRank: 147, overallRankChange: -12, travelRank: 10, travelRankChange: -1, widgetsRank: 12, widgetsRankChange: -5 },
    { id: uuidv4(), country: "Italy", countryImage: ItalyFlag, overallRank: 220, overallRankChange: -56, travelRank: 11, travelRankChange: -3, widgetsRank: 89, widgetsRankChange: 2 }
];

const invoiceItems = [
    { id: uuidv4(), item: "Origin License", description: "Extended License", price: "999,00", quantity: 1 },
    { id: uuidv4(), item: "Custom Services", description: "Instalation and Customization (cost per hour)", price: "150,00", quantity: 20 },
    { id: uuidv4(), item: "Hosting", description: "1 year subcription", price: "499,00", quantity: 1 },
    { id: uuidv4(), item: "Platinum Support", description: "1 year subcription 24/7", price: "3999,00", quantity: 1 },
];

const ideas = [
    { id: uuidv4(), name: "IID09 - Finance Reconcilliaton ", rank: 1, frequency: "Weekly", hourlyEffort: 60, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$156,000" },
    { id: uuidv4(), name: "IID10 - Employee onboarding", rank: 2, frequency: "Weekly", hourlyEffort: 60, costType: "Salary", annualSalary: "$50,000", annuanBenefit: "$130,000"},
    { id: uuidv4(), name: "IID11 - Automated Service Desk Response", rank: 3, frequency: "Monthly", hourlyEffort: 200, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$120,000" }
];

const solutions = [
    { id: uuidv4(), name: "SID1 - Finance Resconcilliation", rank: 1, frequency: "Weekly", hourlyEffort: 60, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$156,000" },
    { id: uuidv4(), name: "SID2 - Employee Onboarding", rank: 2, frequency: "Weekly", hourlyEffort: 60, costType: "Salary", annualSalary: "$50,000", annuanBenefit: "$130,000"},
    { id: uuidv4(), name: "SID3 - Accruals Reminder", rank: 3, frequency: "Monthly", hourlyEffort: 200, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$120,000" },
    { id: uuidv4(), name: "SID4 - Automatic Service Desk Response", rank: 4, frequency: "Monthly", hourlyEffort: 200, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$120,000" },
    { id: uuidv4(), name: "SID5 - Automated Energy Management", rank: 5, frequency: "Weekly", hourlyEffort: 60, costType: "Salary", annualSalary: "$50,000", annuanBenefit: "$130,000" },
    { id: uuidv4(), name: "SID6 - Automatic Employee Interview screening", rank: 6, frequency: "Monthly", hourlyEffort: 200, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$120,000" },
    { id: uuidv4(), name: "SID7 - Invoice Processing", rank: 7, frequency: "Weekly", hourlyEffort: 60, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$156,000" },
    { id: uuidv4(), name: "SID8 - Credit card cancellation", rank: 8, frequency: "Monthly", hourlyEffort: 200, costType: "Hourly", hourlyRate: "$50", annuanBenefit: "$120,000" }
];

const solutionQueues = [
    { id: uuidv4(), name: "QID1 - Finance Resconcilliation", relatedSolution: "SID1 - Finance Resconcilliation", rank: 1, frequency: "Weekly", expectedVolume: 200, valuePerTransaction: 15, expectedCompletionRate: 95 },
    { id: uuidv4(), name: "QID2 - Employee Onboarding", relatedSolution: "SID2 - Employee Onboarding", rank: 2, frequency: "Weekly", expectedVolume: 130, valuePerTransaction: 12, expectedCompletionRate: 80},
    { id: uuidv4(), name: "QID3 - Accruals Reminder", relatedSolution: "SID3 - Accruals Reminder", rank: 3, frequency: "Monthly", expectedVolume: 550, valuePerTransaction: 5, expectedCompletionRate: 95},
    { id: uuidv4(), name: "QID4 - Automatic Service Desk Response", relatedSolution: "SID4 - Automatic Service Desk Response", rank: 4, frequency: "Monthly", expectedVolume: 400, valuePerTransaction: 6, expectedCompletionRate: 95},
    { id: uuidv4(), name: "QID5 - Automated Energy Management", relatedSolution: "SID5 - Automated Energy Management", rank: 5, frequency: "Monthly", expectedVolume: 350, valuePerTransaction: 5, expectedCompletionRate: 95},
    { id: uuidv4(), name: "QID6 - Automatic Employee Interview screening", relatedSolution: "SID6 - Automatic Employee Interview screening", rank: 6, frequency: "Monthly", expectedVolume: 450, valuePerTransaction: 5, expectedCompletionRate: 85},
    { id: uuidv4(), name: "QID7 - Invoice Processing", relatedSolution: "SID7 - Invoice Processing", rank: 7, frequency: "Weekly", expectedVolume: 80, valuePerTransaction: 10, expectedCompletionRate: 95},
    { id: uuidv4(), name: "QID8 - Credit card cancellation 1", relatedSolution: "SID8 - Credit card cancellation", rank: 8, frequency: "Monthly", expectedVolume: 300, valuePerTransaction: 5, expectedCompletionRate: 90},
    { id: uuidv4(), name: "QID9 - Credit card cancellation 2", relatedSolution: "SID9 - Credit card cancellation 2", rank: 9, frequency: "Weekly", expectedVolume: 100, valuePerTransaction: 4, expectedCompletionRate: 95},
    { id: uuidv4(), name: "QID10- Credit card cancellation 3", relatedSolution: "SID10 -Credit card cancellation 3", rank: 10, frequency: "Monthly", expectedVolume: 200, valuePerTransaction: 1, expectedCompletionRate: 98}
];

const businessUnits = [
    { id: uuidv4(), name: "Engineering", headOfUnit: "Tylor Bunting", solutionCount: 3, ideaCount: 5, forecastedBenefit: "$356,000", staffCount: 20},
    { id: uuidv4(), name: "Sales", headOfUnit: "Jonathan Parsons", solutionCount: 1, ideaCount: 2, forecastedBenefit: "$220,000", staffCount: 5},
    { id: uuidv4(), name: "Marketing", headOfUnit: "Selena Bunting", solutionCount: 2, ideaCount: 2, forecastedBenefit: "$286,000", staffCount: 10},
    { id: uuidv4(), name: "Operations", headOfUnit: "Karen Chansuyco", solutionCount: 3, ideaCount: 3, forecastedBenefit: "$456,000", staffCount: 30}
];

export {
    pageVisits,
    pageTraffic,
    pageRanking,
    invoiceItems,
    ideas,
    solutions,
    solutionQueues,
    businessUnits,
};