import React, { useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import * as d3 from "d3";

const WaterfallChart = (props) => {
  const { wb, wfFilterObject, wfChartName, year, WFHeight, WFWidth } = props;
  var reCunstructedData = [];
  var count = 0;
  const ref = useRef();

  //   useEffect(() => {
  //     draw();
  // }, [reCunstructedData]);

  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    if (wfChartName === "Solutions Released into production") {
      var data = getAutomationReleasedInProductionData(
        wb,
        wfFilterObject.filterObject
      );
      var Year = new Date(year).getFullYear();
      var currentYearRecords = getSelectedYearRecordsOnly(data, Year);

      for (let i = 0; i < currentYearRecords.x.length; i++) {
        reCunstructedData.push({
          name: currentYearRecords.x[i],
          value: currentYearRecords.y[i],
        });
      }
      if (reCunstructedData !== []) {
        draw(WFWidth, WFHeight);
      }
    } else if (wfChartName === "") {
      var data = getCountOfProcessesData(wb, wfFilterObject.filterObject);

      for (let i = 0; i < data.x.length; i++) {
        reCunstructedData.push({ name: data.x[i], value: data.y[i] });
      }
      if (reCunstructedData !== []) {
        draw(WFWidth, WFHeight);
      }
    }
  }

  function getSelectedYearRecordsOnly(data, year) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var filteredIndexes = [];

    var filterDates = data.x.filter((el, index) => {
      if (el.indexOf(year) > -1) {
        filteredIndexes.push(index);
        return el;
      }
    });

    var filterValues = [];
    filteredIndexes.forEach((i) => {
      filterValues.push(data.y[i]);
    });

    var missingMonthsOfSelectedYear = [];
    var missingYearValues = [];
    var indexesForMerging = [];

    monthNames.forEach((element) => {
      var monthWithYear = element + " " + year;
      if (filterDates.indexOf(monthWithYear) == -1) {
        missingMonthsOfSelectedYear.push(monthWithYear);
        missingYearValues.push(0);
      }
    });
    var sortedFilteredDate = filterDates.sort();
    sortedFilteredDate.forEach((ele, index) => {
      var splittedDate = ele.split(" ")[0];
      var indexToPush = monthNames.indexOf(splittedDate);
      missingMonthsOfSelectedYear.splice(indexToPush, 0, ele);
      missingYearValues.splice(indexToPush, 0, filterValues[index]);
    });

    return {
      x: missingMonthsOfSelectedYear,
      y: missingYearValues,
      text: missingYearValues.map(String),
    };
  }

  function getAutomationReleasedInProductionData(workbookData, filterObject) {

    var trace1 = {
      x: [],
      y: [],
      text: [],
    };
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    if (Object.keys(workbookData).length > 0) {
      //will check if array conains an value
      Array.prototype.contains = function (v) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === v) return true;
        }
        return false;
      };

      //return unique values from array
      Array.prototype.unique = function () {
        var arr = [];
        for (var i = 0; i < this.length; i++) {
          if (!arr.contains(this[i])) {
            arr.push(this[i]);
          }
        }
        return arr;
      }

      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
        })
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.StageKey !== "" && ele.StageKey === filterObject.StageOfDevelopment;
        })
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        })
      }

      //get names from fact solution sheet to map with fact queue performance sheet
      var solutionNames = filteredSheetData.map(element => {
        return element.Name;
      })

      var uniqueNames = solutionNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })


      //read Dim date comissioned to map names with solution names
      var dimDateCommissionedSheet = workbookData.Sheets['DimDateCommissioned'];
      var dimDateCommissionedSheetData = XLSX.utils.sheet_to_json(dimDateCommissionedSheet, {
        raw: false, defval: "",
        blankRows: false,
      });

      //map fact solution sheet names with Dim Date Comissioned automation names
      var filteredDimDateCommissioned = [];
      uniqueNames.forEach(name => {
        dimDateCommissionedSheetData.forEach(element => {
          if (element["Automation Name"].replace(/ /g, "") === name.replace(/ /g, "") && element["Date Commissioned"] !== "") {
            filteredDimDateCommissioned.push(element);
          };
        });
      });

      //Sheet data for X-Axis
      var xAxisValues = filteredDimDateCommissioned.map(element => {
        var year = new Date(element["Date Commissioned"]).getFullYear();
        var month = monthNames[new Date(element["Date Commissioned"]).getMonth()];
        return month + " " + year;
      })


      var xAxisUniqueValues = xAxisValues.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      //matching Y-Axis sheet data with X-Axis Date
      var yAxisFilteredObject = [];
      xAxisUniqueValues.forEach(date => {
        var list = filteredDimDateCommissioned.filter(element => {
          var formattedDate = monthNames[new Date(element["Date Commissioned"]).getMonth()] + " " + new Date(element["Date Commissioned"]).getFullYear()
          element["Count of Processes"] = 1
          return formattedDate === date && element["Automation Name"] !== "";
        })
        if (list.length > 0) {
          Array.prototype.push.apply(yAxisFilteredObject, list)
        }
        else {
          var emptyObject = {
            "Date Commissioned": new Date(date).toLocaleDateString(),
            "Count of Processes": "0"
          }
          yAxisFilteredObject.push(emptyObject)
        }
      })

      //Getting Count of processes against Date Commissioned
      var summedValues = Object.values(yAxisFilteredObject.reduce((obj, item) => {
        var key = monthNames[new Date(item["Date Commissioned"]).getMonth()] + " " + new Date(item["Date Commissioned"]).getFullYear();
        if (!obj[key]) {
          if (item["Count of Processes"] === "0") {
            item["Count of Processes"] = 0;
          }
          obj[key] = Object.assign(item)
        } else {
          obj[key]["Count of Processes"] += 1
        }
        return obj
      }, {}))

      var yAxisValues = [];
      summedValues.forEach(element => {
        yAxisValues.push(element["Count of Processes"]);
      });
      trace1.x = xAxisUniqueValues;
      trace1.y = yAxisValues;
      trace1.text = yAxisValues.map(String);
    }
    return trace1;
  }

  function getCountOfProcessesData(workbookData, filterObject) {
    var trace1 = {
      x: [],
      y: [],
      text: [],
    };
    if (Object.keys(workbookData).length > 0) {
      var factSolutionSheet = workbookData.Sheets["FactSolution"];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.BusinessUnitKey !== "" &&
            ele.BusinessUnitKey === filterObject.BusinessUnit
          );
        });
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio
          );
        });
      }
      if (filterObject.StageOfDevelopment !== 0) {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return (
            ele.StageKey !== "" &&
            ele.StageKey === filterObject.StageOfDevelopment
          );
        });
      }
      if (filterObject.SolutionsName !== "") {
        filteredSheetData = filteredSheetData.filter((ele) => {
          return ele.Name !== "" && ele.Name === filterObject.SolutionsName;
        });
      }

      //dimStage Sheet data
      var dimStageSheet = workbookData.Sheets["DimStage"];
      var dimStageData = XLSX.utils.sheet_to_json(dimStageSheet, {
        raw: true,
        defval: "",
        blankRows: false,
      });

      var filteredRecords = [];
      dimStageData.forEach((stage) => {
        filteredSheetData.forEach((value) => {
          if (value.StageKey === stage.StageKey) {
            var obj = {
              StageKey: stage.StageKey,
              "Stage of Development": stage["Stage of Development"],
            };
            filteredRecords.push(obj);
          }
        });
      });

      var result = filteredRecords.reduce(
        (acc, o) => (
          (acc[o["Stage of Development"]] =
            (acc[o["Stage of Development"]] || 0) + 1),
          acc
        ),
        {}
      );
      trace1.x = Object.keys(result);
      trace1.y = Object.values(result);
      trace1.text = Object.values(result).map(String);
    }
    return trace1;
  }

  function draw(chartWidth, chartHeight) {
    d3.select(ref.current).html("");
    const svg = d3.select(ref.current).attr("class", "waterFallClass");
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };

    const width = chartWidth - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;
    const padding = 0.3;

    const x = d3.scaleBand().rangeRound([0, width]).padding(padding);

    const y = d3.scaleLinear().range([height, 0]);

    var gridlines = d3.axisLeft().tickFormat("").tickSize(-width).scale(y).ticks(3);

    const xAxis = d3.axisBottom(x);

    const yAxis = d3.axisLeft(y).tickFormat((d) => {
      return d;
    }).ticks(3);

    const chart = d3
      //.select('.chart')
      .select(ref.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style("font-size", 12)
      .style("font-family", '"Inter", sans-serif')
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const type = (d) => {
      d.value = +d.value;
      return d;
    }; // type

    const eurFormat = (amount) => {
      if (Math.abs(amount) > 1000000) {
        return `${Math.round(amount / 1000000)}M`;
      }
      if (Math.abs(amount) > 1000) {
        return `${Math.round(amount / 1000)}K`;
      }
      return `${amount}`;
    }; // eurFormat

    const drawWaterfall = (reCunstructedData) => {
      x.domain(
        reCunstructedData.map((d) => {
          return d.name;
        })
      );

      y.domain([
        0,
        d3.max(reCunstructedData, (d) => {
          return d.end;
        }),
      ]);

      chart
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${height})`)
        .style("font-size", 12)
        .style("font-family", '"Inter", sans-serif')
        .style("fill", "rgb(68, 68, 68)")
        .call(xAxis);

      chart
        .append("g")
        .attr("class", "y axis")
        .style("font-size", 12)
        .style("font-family", '"Inter", sans-serif')
        .style("file", "rgb(68, 68, 68)")
        .call(yAxis)
        .append("g")
        .attr("class", "gridLine")
        .attr("stroke", "#ddd")
        .style("opacity", 0.2)
        .call(gridlines);
      //.style("opacity",0);

      // set chart left axis label
      chart
        .append('text')
        .attr('x', -height / 2)
        .attr('y', - margin.left + 10)
        .attr('dy', '.35em')
        .attr("transform", "rotate(-90)")
        .text("Count of Process")
        .style("fill", "rgb(68, 68, 68)")
        .style("font-family", '"Inter", sans-serif')
        .style('font-weight', 'bold')
        .style('text-anchor', 'middle');

      const bar = chart
        .selectAll(".bar")
        .data(reCunstructedData)
        .enter()
        .append("g")
        .attr("class", (d) => {
          return `bar ${d.class}`;
        })
        .style("fill", (d) => {
          if (d.class === "positive") return "#a0d1ff";
          if (d.class === "negative") return "red";
          if (d.class === "total") return "#0059ff";
        })
        .attr("transform", (d) => {
          return `translate(${x(d.name)},0)`;
        });
      bar
        .append("rect")
        .attr("y", (d) => {
          return y(Math.max(d.start, d.end));
        })
        .attr("height", (d) => {
          return Math.abs(y(d.start) - y(d.end));
        })
        .attr("width", x.bandwidth());

      // Add the value on each bar
      bar
        .append("text")
        .attr("x", x.bandwidth() / 2)
        .attr("y", (d) => {
          return d.class === "positive" ? y(d.end) : y(d.start);
        })
        .attr("dy", "-.35em")
        .text((d) => {
          return d.class === "total"
            ? eurFormat(d.start - d.end)
            : eurFormat(d.end - d.start);
        })
        .style("fill", "black")
        .style("text-anchor", "middle");

      bar.filter((d, i) => {
        // filter out first bar and total bars
        return d.class !== "total" && i !== 0;
      });

      bar.filter((d, i) => {
        // filter out first bar and total bars
        return d.class !== "total" && i !== 0;
      });

      // Add the connecting line between each bar
      bar
        .filter((d, i) => {
          return i !== reCunstructedData.length - 1;
        })
        .append("line")
        .attr("class", "connector")
        .attr("x1", 0)
        .attr("y1", (d) => {
          return d.class === "total" ? y(d.start) : y(d.end);
        })
        .attr("x2", x.bandwidth() / (1 - padding) + x.bandwidth())
        .attr("y2", (d) => {
          return d.class === "total" ? y(d.start) : y(d.end);
        })
        .style("stroke", "gray");
      chart.selectAll(".domain").style("opacity", 0);
      chart.selectAll(".tick line").style("opacity", 0);

      chart.select(".y").selectAll(".tick").style("opacity", function (d, i) {
        if (d % 1 == 0) {
          return 1;
        }
        else {
          return 0;
        }

      });

      var X_bandwidth = x.bandwidth();
      chart.select(".x").selectAll(".tick").style("opacity", function (d, i) {

        if (X_bandwidth > 16) {
          return 1
        }
        else if (X_bandwidth > 9 && X_bandwidth <= 16) {
          if (i % 2 == 0) {
            return 1;
          }
          else {
            return 0;
          }
        }
        else if (X_bandwidth > 5 && X_bandwidth <= 9) {
          if (i % 3 == 0) {
            return 1;
          }
          else {
            return 0;
          }
        }
        else {
          if (i % 4 == 0) {
            return 1;
          }
          else {
            return 0;
          }
        }
      });


      chart.selectAll(".gridLine line")
        .style("opacity", 0.4)
        .style("stroke-dasharray", "4,4");
      chart.selectAll(".x .tick text")
        .attr("transform", "rotate(30 -15 -22)")
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "start");
    }; // drawWaterfall

    const prepData = (reCunstructedData) => {
      // create stacked remainder
      const insertStackedRemainderAfter = (dataName, newDataName) => {
        const index = reCunstructedData.findIndex((datum) => {
          return datum.name === dataName;
        }); // data.findIndex

        return reCunstructedData.splice(index + 1, 0, {
          name: newDataName,
          start: reCunstructedData[index].end,
          end: 0,
          class: "total",
        }); // data.splice
      }; // insertStackedRemainder

      // retrieve total value

      let cumulative = 0;

      // Transform data (i.e., finding cumulative values and total) for easier charting
      reCunstructedData.map((datum) => {
        datum.start = cumulative;
        cumulative += datum.value;
        datum.end = cumulative;
        return (datum.class = datum.value >= 0 ? "positive" : "negative");
      }); // data.map

      // insert stacked remainders where approriate

      insertStackedRemainderAfter(reCunstructedData[reCunstructedData.length - 1].name, "Total");

      return drawWaterfall(reCunstructedData);
      // prepData
    };
    if (count === 0) {
      prepData(reCunstructedData);
      count++;
    }
  }

  return (
    <>
      <div className="chart">
        <svg ref={ref}></svg>
      </div>
    </>
  );
};

export default WaterfallChart;
