import React from "react";
import * as XLSX from "xlsx";

import createPlotlyComponent from "react-plotlyjs";
import Plotly from "plotly.js/dist/plotly-cartesian";

const BacklogBarChart = (props) => {
  const { wb, BarChartFilterObject } = props;

  const PlotlyComponent = createPlotlyComponent(Plotly);

  var BacklogBarChartData = {
    data: [
      {
        type: "bar",
        x: [],
        y: [],
        text: [],
        marker: {
          color: ["#0059ff", "#e6e6e6"],
        },
      },
    ],
    layout: {
      //   title: "Responsive to window's size!",
      font: {
        size: 12,
        family: '"Inter", sans-serif',
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: false,
        title: {
          text: "<b>Ideas count</b>",
          font: {
            size: 12,
            family: '"Inter", sans-serif',
          },
        },
      },
      margin: { 'pad': 5 , l: 30, r: 30, b: 40, t: 10 },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      height: 190,
    },
    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true,
    },
  };


  if (
    !(
      wb &&
      Object.keys(wb).length === 0 &&
      Object.getPrototypeOf(wb) === Object.prototype
    )
  ) {
    var data = automationBacklogIdeaCount(
      wb,
      BarChartFilterObject.filterObject
    );

    BacklogBarChartData.data[0].x = data.x;
    BacklogBarChartData.data[0].y = data.y;
    BacklogBarChartData.data[0].text = data.text;
  }

  function automationBacklogIdeaCount(workbookData, filterObject) {
    var data = {
      x: [],
      y: [],
      text: []
    };

    if (Object.keys(workbookData).length > 0) {

      var factSolutionSheet = workbookData.Sheets['FactSolution'];
      var sheetData = XLSX.utils.sheet_to_json(factSolutionSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      var filteredSheetData = sheetData;

      //filter fact solution sheet based on selected filters
      if (filterObject.BusinessUnit !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.BusinessUnitKey !== "" && ele.BusinessUnitKey === filterObject.BusinessUnit;
        })
      }
      if (filterObject.Portfolio !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.PortfolioKey !== "" && ele.PortfolioKey === filterObject.Portfolio;
        })
      }
      if (filterObject.IdeaStatusKey !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.IdeaStatusKey !== "" && ele.IdeaStatusKey === filterObject.IdeaStatusKey;
        })
      }
      if (filterObject.ApprovalKey !== 0) {
        filteredSheetData = filteredSheetData.filter(ele => {
          return ele.ApprovalKey !== "" && ele.ApprovalKey === filterObject.ApprovalKey;
        })
      }


      //DimIdeaStatus Sheet data
      var dimIdeaStatusSheet = workbookData.Sheets['DimIdeaStatus'];
      var dimIdeaStatusData = XLSX.utils.sheet_to_json(dimIdeaStatusSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      //filter fact solution sheet records based upon their Idea Status
      var mappedData = [];
      dimIdeaStatusData.forEach(dimIdeas => {
        filteredSheetData.forEach(factSolution => {
          if (factSolution.IdeaStatusKey === dimIdeas.IdeaStatusKey) {
            factSolution.IdeaStatus = dimIdeas.IdeaStatus;
            mappedData.push(factSolution);
          }
        })
      })

      //DimApproval Sheet data
      var dimApprovalSheet = workbookData.Sheets['DimApproval'];
      var dimApprovalData = XLSX.utils.sheet_to_json(dimApprovalSheet, {
        raw: true, defval: "",
        blankRows: false,
      });

      //filter fact solution sheet records based upon their Approval Status
      var mappedData = [];
      dimApprovalData.forEach(dimApproval => {
        filteredSheetData.forEach(factSolution => {
          if (factSolution.ApprovalKey === dimApproval.ApprovalKey) {
            factSolution["Approved/Backlog"] = dimApproval["Approved/Backlog"];
            mappedData.push(factSolution);
          }
        })
      })

      var approved = mappedData.filter(element => {
        return element["Approved/Backlog"] == "Approved";
      })

      var backlogs = mappedData.filter(element => {
        return element["Approved/Backlog"] == "Backlog";
      })
      data.x = ["Backlog", "Approved"];
      data.y = [backlogs.length, approved.length];
      data.text = data.y.map(String);

    }
    return data;
  }

  return (
    <>
      <div className="rounded">
        <PlotlyComponent
          style={{ width: "100%" }}
          data={BacklogBarChartData.data}
          layout={BacklogBarChartData.layout}
          config={BacklogBarChartData.config}
        />
      </div>
    </>
  );
};

export default BacklogBarChart;
